import React from "react";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import FlightIcon from "@mui/icons-material/Flight";
import { Typography } from "@mui/material";
import RouteMenu from "./RouteMenu";
import { useAuth } from "../../../auth/AuthContext";

export default function Route({ item, dayIndex, index }) {
  const { currentUser } = useAuth();

  const routeData = React.useMemo(() => {
    if (item.route) {
      let units = "km";
      let distance = item.route.distance;
      if (currentUser.distanceUnits === "M") {
        distance *= 0.6213712;
        units = "mi";
      }

      distance = Math.round(distance / 100) / 10;
      const totalMins = Math.round(item.route.time / 60);
      const hours = Math.floor(totalMins / 60);
      const mins = Math.floor(totalMins % 60);
      return { distance, units, totalMins, hours, mins };
    } else {
      return undefined;
    }
  }, [item]);
  return (
    <>
      {item.isFlight ? (
        <FlightIcon fontSize="small" sx={{ mr: 1 }}></FlightIcon>
      ) : (
        <DriveEtaIcon fontSize="small" sx={{ mr: 1 }}></DriveEtaIcon>
      )}
      <Typography variant="caption" sx={{ width: 150 }}>
        {routeData.distance +
          ` ${routeData.units} | ` +
          (routeData.hours > 0 ? routeData.hours + " hr " : "") +
          routeData.mins +
          " min"}
      </Typography>
      <RouteMenu item={item} dayIndex={dayIndex} index={index}></RouteMenu>
    </>
  );
}
