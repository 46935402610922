import React from "react";
import ItineraryItemContainer from "./ItineraryItemContainer";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import {
  addEmptyItineraryItem,
  addDay,
  getColorCodedDaysPref,
  getItineraryRouteStatus,
  getItineraryStatus,
  setSelectedDay,
} from "../../../redux/itinerary";
import LastItem from "./LastItem";
import AddIcon from "@mui/icons-material/Add";
import {
  Typography,
  Tooltip,
  IconButton,
  Box,
  Button,
  ButtonBase,
} from "@mui/material";
import midString from "../../../util/order";
import EditDayColor from "./EditDayColor";
import DayMenu from "./DayMenu";

export default function ListView({
  itinerary,
  onDragEnd,
  onDragStart,
  isDragging,
}) {
  const dispatch = useDispatch();
  const routeStatus = useSelector(getItineraryRouteStatus);
  const itineraryStatus = useSelector(getItineraryStatus);
  const colorCodedDays = useSelector(getColorCodedDaysPref);

  function handleAddDay() {
    dispatch(addDay(null));
  }

  function handleAddNewItemToDay(dayIdx) {
    let prev = "";

    const dayItems = itinerary.days[dayIdx].items;

    if (dayItems.length > 0) {
      prev = dayItems[dayItems.length - 1].order;
    }

    const orderString = midString(prev, "");

    dispatch(addEmptyItineraryItem({ day: dayIdx, order: orderString }));
  }

  const handleSetSelectedDay = (day) => {
    dispatch(setSelectedDay({ day: day }));
  };

  return (
    <>
      <ItineraryStyle>
        <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
          {itinerary.days.map((day, dayIndex) => (
            <div key={dayIndex}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  {colorCodedDays ? <Spacer /> : <></>}

                  <ButtonBase
                    sx={{
                      pl: 1,
                      pr: 1,
                      mr: 1,
                      border: "none",
                      borderRadius: "4px",

                      "&:hover": {
                        color: "text.secondary",
                      },
                    }}
                    onClick={() => handleSetSelectedDay(dayIndex)}
                  >
                    <Typography variant="h6">Day {dayIndex + 1}</Typography>
                  </ButtonBase>
                  {colorCodedDays ? (
                    <EditDayColor
                      dayColor={day.color}
                      dayIndex={dayIndex}
                    ></EditDayColor>
                  ) : (
                    <></>
                  )}
                </Box>

                <DayMenu dayIndex={dayIndex}></DayMenu>
              </Box>

              <DayColorBorderLeft
                color={day.color}
                colorCodedDays={colorCodedDays}
              >
                <LastItem
                  dayIndex={dayIndex}
                  itinerary={itinerary}
                  isDragging={isDragging}
                ></LastItem>
                <Droppable droppableId={`${dayIndex}`}>
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {day.items && day.items.length > 0 ? (
                        day.items.map((item, index) => (
                          <Draggable
                            draggableId={item.itemId}
                            index={index}
                            key={item.itemId}
                            isDragDisabled={
                              itineraryStatus === "loading" ||
                              routeStatus === "loading"
                            }
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                              >
                                <DraggableStyle>
                                  <ItineraryItemContainer
                                    item={item}
                                    snapshot={snapshot}
                                    index={index}
                                    dayIndex={dayIndex}
                                    dayItemsLength={
                                      itinerary.days[dayIndex].items.length
                                    }
                                    isAnyDragging={isDragging}
                                    provided={provided}
                                  />
                                </DraggableStyle>
                              </div>
                            )}
                          </Draggable>
                        ))
                      ) : (
                        <>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Typography
                              variant="subtitle2"
                              sx={{ color: "rgba(0,0,0,0.5)", mb: 1 }}
                            >
                              Day Empty
                            </Typography>
                          </Box>
                        </>
                      )}

                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DayColorBorderLeft>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {colorCodedDays ? <Spacer /> : <></>}
                {itineraryStatus === "loading" || routeStatus === "loading" ? (
                  <IconButton disabled>
                    <AddIcon></AddIcon>
                  </IconButton>
                ) : (
                  <Tooltip
                    title={`Add Stop to Day ${dayIndex + 1}`}
                    placement="right"
                    enterDelay={500}
                  >
                    <IconButton
                      disabled={
                        itineraryStatus === "loading" ||
                        routeStatus === "loading"
                      }
                      onClick={() => handleAddNewItemToDay(dayIndex)}
                    >
                      <AddIcon></AddIcon>
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
            </div>
          ))}
        </DragDropContext>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          <Button
            onClick={handleAddDay}
            disabled={
              itineraryStatus === "loading" || routeStatus === "loading"
            }
            variant="text"
            color="secondary"
          >
            Add Day
          </Button>
        </Box>
      </ItineraryStyle>
    </>
  );
}

const ItineraryStyle = styled.div`
  background-color: rgb(247, 248, 247);
  padding: 16px;
  min-height: calc(100% - 32px);
`;

const DraggableStyle = styled.div`
  padding-bottom: 10px;
`;

const Spacer = styled.div`
  margin-left: 14px;
`;

const DayColorBorderLeft = styled.div`
  ${(props) =>
    props.colorCodedDays
      ? `border-left: solid 3px ${props.color};
        padding-left: 12px;`
      : `border-left: none;
        padding-left: 0px;`}
  padding-top: 8px
`;
