import { configureStore } from "@reduxjs/toolkit";

import alertReducer from "./alert";
import itineraryReducer from "./itinerary";

const store = configureStore({
  reducer: {
    alert: alertReducer,
    itinerary: itineraryReducer,
  },
  devTools: process.env.NODE_ENV !== "production",
});

export default store;
