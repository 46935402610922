import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Divider, ListItemIcon, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthContext";
import { Box } from "@mui/system";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import LogoutIcon from "@mui/icons-material/Logout";
import CalendarViewMonthIcon from "@mui/icons-material/CalendarViewMonth";

export default function AccountButton() {
  const settings = ["Profile", "Account", "Logout"];

  const { currentUser } = useAuth();

  const navigate = useNavigate();
  const { logout } = useAuth();

  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  if (currentUser) {
    return (
      <Box sx={{ flexGrow: 0 }}>
        <Tooltip title={currentUser.username}>
          <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
            <Avatar>{currentUser.username.charAt(0).toUpperCase()}</Avatar>
          </IconButton>
        </Tooltip>
        <Menu
          sx={{ mt: "45px" }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          <MenuItem key="Itineraries" onClick={() => navigate("../")}>
            <ListItemIcon>
              <CalendarViewMonthIcon></CalendarViewMonthIcon>
            </ListItemIcon>
            <Typography textAlign="center">Itineraries</Typography>
          </MenuItem>
          <MenuItem key="Account" onClick={() => navigate("../account")}>
            <ListItemIcon>
              <ManageAccountsIcon></ManageAccountsIcon>
            </ListItemIcon>
            <Typography textAlign="center">Account</Typography>
          </MenuItem>
          <Divider />
          <MenuItem key="Logout" onClick={handleLogout}>
            <ListItemIcon>
              <LogoutIcon color="error"></LogoutIcon>
            </ListItemIcon>
            <Typography color="error" textAlign="center">
              Logout
            </Typography>
          </MenuItem>
        </Menu>
      </Box>
    );
  }
}
