import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useState } from "react";

import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthContext";
import { useDispatch } from "react-redux";
import { createAlert } from "../redux/alert";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import YupPassword from "yup-password";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

YupPassword(Yup); // extend yup

export default function ChangePassword() {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { getSession, logout } = useAuth();

  const formValidationSchema = Yup.object().shape({
    currentPassword: Yup.string()
      .required("Current password is required")
      .min(8, "Password length should be at least 8 characters"),
    newPassword: Yup.string()
      .required("Password is required")
      .min(8, "Password length should be at least 8 characters")
      .minLowercase(1, "Password must contain at least 1 lower case letter")
      .minUppercase(1, "Password must contain at least 1 upper case letter")
      .minNumbers(1, "Password must contain at least 1 number")
      .minSymbols(1, "Password must contain at least 1 special character"),
    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf(
        [Yup.ref("newPassword")],
        "Confirmation password does not match new password"
      ),
  });

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors, dirtyFields, isDirty, isValid },
  } = useForm({
    resolver: yupResolver(formValidationSchema, {
      validateAllFieldCriteria: true,
    }),
    defaultValues: {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    criteriaMode: "all",
    mode: "onChange",
  });

  const [showPasswordCurrent, setShowPasswordCurrent] = useState(false);
  const handleClickShowPasswordCurrent = () =>
    setShowPasswordCurrent(!showPasswordCurrent);
  const handleMouseDownPasswordCurrent = () =>
    setShowPasswordCurrent(!showPasswordCurrent);

  const [showNewPassword, setShowNewPassword] = useState(false);
  const handleClickShowNewPassword = () => setShowNewPassword(!showNewPassword);
  const handleMouseDownNewPassword = () => setShowNewPassword(!showNewPassword);

  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const handleClickShowPasswordConfirm = () =>
    setShowPasswordConfirm(!showPasswordConfirm);
  const handleMouseDownPasswordConfirm = () =>
    setShowPasswordConfirm(!showPasswordConfirm);

  const handleChangePassword = (formData) => {
    const currentPassword = formData.currentPassword;
    const newPassword = formData.newPassword;

    getSession().then(({ user }) => {
      user.changePassword(currentPassword, newPassword, (e, result) => {
        if (e) {
          if (e.message === "Incorrect username or password.") {
            dispatch(
              createAlert({
                message: "Incorrect current password.",
                type: "error",
              })
            );
          } else {
            dispatch(createAlert({ message: e.message, type: "error" }));
          }
          reset();
        } else {
          logout();
          navigate("../login", { replace: true });
        }
      });
    });
  };

  return (
    <Box component="form" onSubmit={handleSubmit(handleChangePassword)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            name="currentPassword"
            label="Current Password"
            id="currentPassword"
            autoComplete="current-password"
            {...register("currentPassword")}
            error={!!errors?.currentPassword}
            helperText={
              errors.currentPassword ? errors.currentPassword.message : " "
            }
            type={showPasswordCurrent ? "text" : "password"} // <-- This is where the magic happens
            InputProps={{
              // <-- This is where the toggle button is added.
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPasswordCurrent}
                    onMouseDown={handleMouseDownPasswordCurrent}
                    edge="end"
                  >
                    {showPasswordCurrent ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          container
          direction="column"
          sx={{ marginLeft: "14px", marginRight: "14px" }}
        >
          <Grid item container justifyContent="start">
            <Typography
              variant="subtitle2"
              sx={{ marginBottom: "5px", fontWeight: "1000" }}
            >
              New password must contain:
            </Typography>
          </Grid>

          <Grid item container justifyContent="space-between">
            <Typography
              variant="caption"
              color={
                !errors?.newPassword && !dirtyFields?.newPassword
                  ? "initial"
                  : !errors?.newPassword?.types.min && dirtyFields?.newPassword
                  ? "primary"
                  : "error"
              }
            >
              At least 8 characters
            </Typography>
            {!errors?.newPassword && !dirtyFields?.newPassword ? (
              <CloseIcon fontSize="small" color="initial"></CloseIcon>
            ) : !errors?.newPassword?.types.min && dirtyFields?.newPassword ? (
              <CheckIcon fontSize="small" color="primary"></CheckIcon>
            ) : (
              <CloseIcon fontSize="small" color="error"></CloseIcon>
            )}
          </Grid>
          <Grid item container justifyContent="space-between">
            <Typography
              variant="caption"
              color={
                !errors?.newPassword && !dirtyFields?.newPassword
                  ? "initial"
                  : !errors?.newPassword?.types.minUppercase &&
                    dirtyFields?.newPassword
                  ? "primary"
                  : "error"
              }
            >
              An uppercase letter
            </Typography>
            {!errors?.newPassword && !dirtyFields?.newPassword ? (
              <CloseIcon fontSize="small" color="initial"></CloseIcon>
            ) : !errors?.newPassword?.types.minUppercase &&
              dirtyFields?.newPassword ? (
              <CheckIcon fontSize="small" color="primary"></CheckIcon>
            ) : (
              <CloseIcon fontSize="small" color="error"></CloseIcon>
            )}
          </Grid>
          <Grid item container justifyContent="space-between">
            <Typography
              variant="caption"
              color={
                !errors?.newPassword && !dirtyFields?.newPassword
                  ? "initial"
                  : !errors?.newPassword?.types.minLowercase &&
                    dirtyFields?.newPassword
                  ? "primary"
                  : "error"
              }
            >
              A lowercase letter
            </Typography>
            {!errors?.newPassword && !dirtyFields?.newPassword ? (
              <CloseIcon fontSize="small" color="initial"></CloseIcon>
            ) : !errors?.newPassword?.types.minLowercase &&
              dirtyFields?.newPassword ? (
              <CheckIcon fontSize="small" color="primary"></CheckIcon>
            ) : (
              <CloseIcon fontSize="small" color="error"></CloseIcon>
            )}
          </Grid>
          <Grid item container justifyContent="space-between">
            <Typography
              variant="caption"
              color={
                !errors?.newPassword && !dirtyFields?.newPassword
                  ? "initial"
                  : !errors?.newPassword?.types.minNumber &&
                    dirtyFields?.newPassword
                  ? "primary"
                  : "error"
              }
            >
              A number
            </Typography>

            {!errors?.newPassword && !dirtyFields?.newPassword ? (
              <CloseIcon fontSize="small" color="initial"></CloseIcon>
            ) : !errors?.newPassword?.types.minNumber &&
              dirtyFields?.newPassword ? (
              <CheckIcon fontSize="small" color="primary"></CheckIcon>
            ) : (
              <CloseIcon fontSize="small" color="error"></CloseIcon>
            )}
          </Grid>
          <Grid item container justifyContent="space-between">
            <Typography
              variant="caption"
              color={
                !errors?.newPassword && !dirtyFields?.newPassword
                  ? "initial"
                  : !errors?.newPassword?.types.minSymbol &&
                    dirtyFields?.newPassword
                  ? "primary"
                  : "error"
              }
            >
              A special character
            </Typography>
            {!errors?.newPassword && !dirtyFields?.newPassword ? (
              <CloseIcon fontSize="small" color="initial"></CloseIcon>
            ) : !errors?.newPassword?.types.minSymbol &&
              dirtyFields?.newPassword ? (
              <CheckIcon fontSize="small" color="primary"></CheckIcon>
            ) : (
              <CloseIcon fontSize="small" color="error"></CloseIcon>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            name="newPassword"
            label="New Password"
            id="newPassword"
            autoComplete="new-password"
            {...register("newPassword")}
            error={!!errors?.newPassword}
            type={showNewPassword ? "text" : "password"} // <-- This is where the magic happens
            InputProps={{
              // <-- This is where the toggle button is added.
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowNewPassword}
                    onMouseDown={handleMouseDownNewPassword}
                    edge="end"
                  >
                    {showNewPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            disabled={
              errors?.newPassword || !dirtyFields?.newPassword ? true : false
            }
            name="confirmPassword"
            label="Confirm New Password"
            id="confirmPassword"
            autoComplete="new-password"
            {...register("confirmPassword")}
            error={!!errors?.confirmPassword}
            helperText={
              errors.confirmPassword ? errors.confirmPassword.message : " "
            }
            type={showPasswordConfirm ? "text" : "password"} // <-- This is where the magic happens
            InputProps={{
              // <-- This is where the toggle button is added.
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPasswordConfirm}
                    onMouseDown={handleMouseDownPasswordConfirm}
                    edge="end"
                  >
                    {showPasswordConfirm ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sx={{ marginLeft: "14px", marginRight: "14px" }}>
          <Typography variant="caption">
            After clicking change password you will be automatically signed out
            and required to sign back in using the new password.
          </Typography>
        </Grid>
      </Grid>

      <Button
        type="submit"
        fullWidth
        disabled={!isDirty || !isValid}
        variant="contained"
        sx={{ mt: 2, mb: 2 }}
      >
        Change Password
      </Button>
      <Grid container justifyContent="flex-end">
        <Grid item></Grid>
      </Grid>
    </Box>
  );
}
