import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import UserPool from "./userPoolConfig";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import YupPassword from "yup-password";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useDispatch } from "react-redux";
import { createAlert } from "../redux/alert";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { countries } from "../util/countries";
import ReactCountryFlag from "react-country-flag";
import PublicIcon from "@mui/icons-material/Public";
import axios from "axios";
import Logo from "../Logo";

YupPassword(Yup); // extend yup

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function SignUp() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const formValidationSchema = Yup.object().shape({
    username: Yup.string()
      .required("A username is required")
      .min(3, "Username must contain at least 3 characters")
      .max(25, "Username can not contain more than 25 characters")
      .matches(/^[a-zA-Z0-9_-]+$/, "Invalid username"),
    email: Yup.string()
      .required("An email address is required")
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Invalid email"
      ),
    country: Yup.object()
      .required("Country is required")
      .nullable("please select a country"),
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password length should be at least 8 characters")
      .minLowercase(1, "Password must contain at least 1 lower case letter")
      .minUppercase(1, "Password must contain at least 1 upper case letter")
      .minNumbers(1, "Password must contain at least 1 number")
      .minSymbols(1, "Password must contain at least 1 special character"),
  });

  const {
    register,
    reset,
    setValue,
    control,
    handleSubmit,
    getValues,
    formState: { errors, dirtyFields, isDirty, isValid },
  } = useForm({
    resolver: yupResolver(formValidationSchema, {
      validateAllFieldCriteria: true,
    }),
    criteriaMode: "all",
    mode: "onChange",
  });

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option) => option.label + " " + option.code + " " + option.alt,
  });

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const [timezone, setTimezone] = React.useState();
  useEffect(() => {
    (async () => {
      let country = localStorage.getItem("country");
      let timezone = localStorage.getItem("timezone");
      if (timezone && country) {
        country = countries.find((c) => c.code === country);
        timezone = timezone;
      } else {
        let geolocationData;
        try {
          const response = await axios.get(
            `https://api.ipgeolocation.io/ipgeo?apiKey=${process.env.REACT_APP_GEOLOCATION_KEY}`
          );
          geolocationData = response.data;
          if (geolocationData) {
            localStorage.setItem("country", geolocationData.country_code2);
            localStorage.setItem("timezone", geolocationData.time_zone.name);
            country = countries.find(
              (country) => country.code === geolocationData.country_code2
            );
            timezone = geolocationData.time_zone.name;
          }
        } catch (e) {}
        if (!country) {
          country = countries[230];
        }
        if (!timezone) {
          timezone = "America/New_York";
        }
      }
      setValue("country", country);
      setTimezone(timezone);
    })();
  }, []);

  const handleRegisterUser = (formData) => {
    const username = formData.username;
    const email = formData.email;
    const password = formData.password;
    const country = formData.country;

    const imperialDistanceCountries = [
      "US",
      "UK",
      "PR",
      "BB",
      "GU",
      "IM",
      "KY",
      "AG",
      "GG",
      "JE",
    ];
    const distanceUnits = imperialDistanceCountries.includes(country)
      ? "I"
      : "M";

    UserPool.signUp(
      username.toLowerCase(),
      password,
      [
        {
          Name: "email",
          Value: email,
        },
        {
          Name: "custom:country",
          Value: country.code,
        },
        {
          Name: "custom:timezone",
          Value: timezone,
        },
        {
          Name: "custom:distanceUnits",
          Value: distanceUnits,
        },
      ],
      null,
      (err, data) => {
        if (err) {
          dispatch(createAlert({ message: err.message, type: "error" }));
        } else {
          navigate("/confirm", { replace: true, state: { username, email } });
        }
      }
    );
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 5,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Logo></Logo>

        <Typography
          sx={{ mt: 2, fontWeight: "500" }}
          component="h1"
          variant="subtitle1"
        >
          Sign up
        </Typography>
        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit(handleRegisterUser)}
          sx={{ mt: 2 }}
        >
          <Grid container spacing={2}>
            {/* <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="given-name"
                name="firstName"
                required
                fullWidth
                id="firstName"
                label="First Name"
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="family-name"
              />
            </Grid> */}
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                {...register("username")}
                error={!!errors?.username}
                helperText={errors.username ? errors.username.message : " "}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                {...register("email")}
                error={!!errors?.email}
                helperText={errors.email ? errors.email.message : " "}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                defaultValue={null}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    id="country"
                    options={countries}
                    filterOptions={filterOptions}
                    autoHighlight
                    isOptionEqualToValue={(option, value) => {
                      return option.label === value.label;
                    }}
                    getOptionLabel={(option) => option.label}
                    renderOption={(props, option) => (
                      <Box {...props}>{option.label}</Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Country"
                        variant="outlined"
                        error={!!errors?.country}
                        required
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <>
                              <InputAdornment
                                sx={{ ml: 1, width: "24px" }}
                                position="start"
                              >
                                {field.value?.code ? (
                                  <ReactCountryFlag
                                    svg
                                    countryCode={field.value.code}
                                    style={{
                                      width: "1.75em",
                                      height: "1em",
                                    }}
                                  />
                                ) : (
                                  <PublicIcon></PublicIcon>
                                )}
                              </InputAdornment>
                              {params.InputProps.startAdornment}
                            </>
                          ),

                          autoComplete: "off",
                          // disable autocomplete and autofill
                        }}
                      />
                    )}
                    onChange={(_, data) => field.onChange(data)}
                  />
                )}
                name="country"
                control={control}
              />
            </Grid>
            <Grid
              item
              xs={12}
              container
              direction="column"
              sx={{ marginLeft: "14px", marginRight: "14px" }}
            >
              <Grid item container justifyContent="start">
                <Typography variant="subtitle2" sx={{ marginBottom: "5px" }}>
                  Your password must contain:
                </Typography>
              </Grid>

              <Grid item container justifyContent="space-between">
                <Typography
                  variant="caption"
                  color={
                    !errors?.password && !dirtyFields?.password
                      ? "initial"
                      : !errors?.password?.types.min && dirtyFields?.password
                      ? "primary"
                      : "error"
                  }
                >
                  At least 8 characters
                </Typography>
                {!errors?.password && !dirtyFields?.password ? (
                  <CloseIcon fontSize="small" color="initial"></CloseIcon>
                ) : !errors?.password?.types.min && dirtyFields?.password ? (
                  <CheckIcon fontSize="small" color="primary"></CheckIcon>
                ) : (
                  <CloseIcon fontSize="small" color="error"></CloseIcon>
                )}
              </Grid>
              <Grid item container justifyContent="space-between">
                <Typography
                  variant="caption"
                  color={
                    !errors?.password && !dirtyFields?.password
                      ? "initial"
                      : !errors?.password?.types.minUppercase &&
                        dirtyFields?.password
                      ? "primary"
                      : "error"
                  }
                >
                  An uppercase letter
                </Typography>
                {!errors?.password && !dirtyFields?.password ? (
                  <CloseIcon fontSize="small" color="initial"></CloseIcon>
                ) : !errors?.password?.types.minUppercase &&
                  dirtyFields?.password ? (
                  <CheckIcon fontSize="small" color="primary"></CheckIcon>
                ) : (
                  <CloseIcon fontSize="small" color="error"></CloseIcon>
                )}
              </Grid>
              <Grid item container justifyContent="space-between">
                <Typography
                  variant="caption"
                  color={
                    !errors?.password && !dirtyFields?.password
                      ? "initial"
                      : !errors?.password?.types.minLowercase &&
                        dirtyFields?.password
                      ? "primary"
                      : "error"
                  }
                >
                  A lowercase letter
                </Typography>
                {!errors?.password && !dirtyFields?.password ? (
                  <CloseIcon fontSize="small" color="initial"></CloseIcon>
                ) : !errors?.password?.types.minLowercase &&
                  dirtyFields?.password ? (
                  <CheckIcon fontSize="small" color="primary"></CheckIcon>
                ) : (
                  <CloseIcon fontSize="small" color="error"></CloseIcon>
                )}
              </Grid>
              <Grid item container justifyContent="space-between">
                <Typography
                  variant="caption"
                  color={
                    !errors?.password && !dirtyFields?.password
                      ? "initial"
                      : !errors?.password?.types.minNumber &&
                        dirtyFields?.password
                      ? "primary"
                      : "error"
                  }
                >
                  A number
                </Typography>

                {!errors?.password && !dirtyFields?.password ? (
                  <CloseIcon fontSize="small" color="initial"></CloseIcon>
                ) : !errors?.password?.types.minNumber &&
                  dirtyFields?.password ? (
                  <CheckIcon fontSize="small" color="primary"></CheckIcon>
                ) : (
                  <CloseIcon fontSize="small" color="error"></CloseIcon>
                )}
              </Grid>
              <Grid item container justifyContent="space-between">
                <Typography
                  variant="caption"
                  color={
                    !errors?.password && !dirtyFields?.password
                      ? "initial"
                      : !errors?.password?.types.minSymbol &&
                        dirtyFields?.password
                      ? "primary"
                      : "error"
                  }
                >
                  A special character
                </Typography>
                {!errors?.password && !dirtyFields?.password ? (
                  <CloseIcon fontSize="small" color="initial"></CloseIcon>
                ) : !errors?.password?.types.minSymbol &&
                  dirtyFields?.password ? (
                  <CheckIcon fontSize="small" color="primary"></CheckIcon>
                ) : (
                  <CloseIcon fontSize="small" color="error"></CloseIcon>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="password"
                label="Password"
                id="password"
                autoComplete="new-password"
                {...register("password")}
                error={!!errors?.password}
                type={showPassword ? "text" : "password"} // <-- This is where the magic happens
                InputProps={{
                  // <-- This is where the toggle button is added.
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={!isDirty || !isValid}
          >
            Sign Up
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link href="/login" variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      {/* <Copyright sx={{ mt: 5 }} /> */}
    </Container>
  );
}
