import React from "react";
import styled from "styled-components";
import EditItemLocation from "./EditItemLocation";
import ItemInfoDetail from "./ItemInfoDetail";
import ItemLoading from "./ItemLoading";
import { Skeleton, Typography, Box } from "@mui/material";
import Route from "./Route";

const Container = styled.div`
  border: none;
  border-radius 5px;
  box-shadow: ${(props) =>
    props.isDragging ? "0px 5px 5px darkgrey" : "0px 2px 2px darkgrey"};
  background-color: ${(props) => (props.isDragging ? "white" : "white")};
`;

export default function ItineraryItemContainer({
  item,
  provided,
  snapshot,
  index,
  dayIndex,
  dayItemsLength,
  isAnyDragging,
}) {
  if (item.geo) {
    return (
      <>
        {item.route && !snapshot.isDragging ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              mb: "10px",
              height: "20px",
              color: "rgba(0,0,0,0.6)",
            }}
          >
            <Box
              sx={{
                width: 210,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "start",
              }}
            >
              {isAnyDragging ? (
                <></>
              ) : (
                <Route item={item} dayIndex={dayIndex} index={index}></Route>
              )}
            </Box>
          </Box>
        ) : dayIndex !== 0 || index !== 0 ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              mb: "10px",
              height: "20px",
              color: "rgba(0,0,0,0.6)",
            }}
          />
        ) : (
          <></>
        )}
        <Container
          isDragging={snapshot.isDragging}
          {...provided.dragHandleProps}
        >
          <ItemInfoDetail
            item={item}
            index={index}
            dayIndex={dayIndex}
            dayItemsLength={dayItemsLength}
          />
        </Container>
      </>
    );
  } else if (item.isItemLoadingGeo) {
    return (
      <>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            mb: "10px",
            height: "20px",
            color: "rgba(0,0,0,0.6)",
          }}
        >
          <Typography variant="subtitle2" sx={{ width: 150 }}>
            <Skeleton width={125} animation="wave" />
          </Typography>
        </Box>
        <Container
          isDragging={snapshot.isDragging}
          {...provided.dragHandleProps}
        >
          <ItemLoading item={item} index={index} dayIndex={dayIndex} />
        </Container>
      </>
    );
  } else {
    return (
      <Container isDragging={snapshot.isDragging} {...provided.dragHandleProps}>
        <EditItemLocation item={item} index={index} dayIndex={dayIndex} />
      </Container>
    );
  }
}
