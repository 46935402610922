export const clusterLayer = {
  id: "clusters",
  type: "circle",
  source: "points",
  filter: ["has", "point_count"],
  paint: {
    "circle-color": [
      "step",
      ["get", "point_count"],
      "#000000",
      100,
      "#f1f075",
      750,
      "#f28cb1",
    ],
    "circle-radius": ["step", ["get", "point_count"], 10, 10, 13, 750, 40],
  },
};

export const clusterCountLayer = {
  id: "cluster-count",
  type: "symbol",
  source: "points",
  filter: ["has", "point_count"],
  layout: {
    "text-field": "{point_count_abbreviated}",
    "text-ignore-placement": false,
    "text-size": 12,
    "text-allow-overlap": true,
  },
  paint: {
    "text-color": "#FFFFFF",
  },
};

export const pointLayer = {
  id: "point",
  type: "circle",
  source: "points",
  filter: ["!", ["has", "point_count"]],
  paint: {
    "circle-stroke-width": 3,
    "circle-stroke-color": "#000000",
    "circle-radius": 4,
    "circle-color": "#FFFFFF",
    "circle-opacity": 1,
    "circle-stroke-opacity": 0.8,
    "circle-pitch-alignment": "map",
  },
};

export const pointLabelLayer = {
  id: "point-labels",
  type: "symbol",
  source: "points",
  filter: ["!", ["has", "point_count"]],
  layout: {
    "text-field": ["get", "address"],
    "text-anchor": "bottom",
    "text-font": ["Noto Sans"],
    "text-size": ["interpolate", ["linear"], ["zoom"], 2, 10, 4, 14, 12, 16],
    "text-offset": [0.7, 0.7],
    "text-variable-anchor": ["bottom", "right", "left", "top"],
    "text-allow-overlap": false,
  },
  paint: {
    "text-color": "#000000",
    "text-halo-color": "#FFFFFF",
    "text-halo-width": 1,
  },
};
