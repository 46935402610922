import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Paper,
  IconButton,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  getItinerary,
  getSelectedDay,
  setSelectedDay,
} from "../../redux/itinerary";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useAuth } from "../../auth/AuthContext";

export default function SelectedDayOverlay({ day }) {
  const dispatch = useDispatch();
  const selectedDay = useSelector(getSelectedDay);
  const { currentUser } = useAuth();
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("md"));
  const itinerary = useSelector(getItinerary);
  const dayTotals = React.useMemo(() => {
    let tTotal = 0;
    let dTotal = 0;
    for (let item of day.items) {
      if (item.includeInRouting && item.route) {
        dTotal += item.route.distance;
        tTotal += item.route.time;
      }
    }

    let units = "km";
    if (currentUser.distanceUnits === "M") {
      dTotal *= 0.6213712;
      units = "mi";
    }

    const distance = Math.round(dTotal / 100) / 10;
    const totalMins = Math.round(tTotal / 60);
    const hours = Math.floor(totalMins / 60);
    const mins = Math.floor(totalMins % 60);
    return { distance, units, totalMins, hours, mins };
  }, [day]);

  const handleCloseSelectedDay = () => {
    dispatch(setSelectedDay({ day: null }));
  };

  const handleChangeSelectedDay = (day) => {
    if (day >= 0 && day < itinerary.days.length) {
      dispatch(setSelectedDay({ day: day }));
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "end",
        height: "100%",
        width: "100%",
      }}
    >
      <Paper
        sx={
          isSmallDevice
            ? {
                width: "300px",
                height: "85px",
                mb: "10px",
                zIndex: "2",
              }
            : {
                width: "300px",
                height: "100px",
                mb: "25px",
                zIndex: "2",
              }
        }
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            height: "100%",
          }}
        >
          <Box
            sx={{
              flexGrow: "0",
              display: "flex",
              flexDirection: "column",
              mt: "31px",
              justifyContent: "start",
            }}
          >
            <IconButton
              sx={{ ml: 1 }}
              disabled={selectedDay === 0}
              onClick={() => handleChangeSelectedDay(selectedDay - 1)}
            >
              <NavigateBeforeIcon></NavigateBeforeIcon>
            </IconButton>
          </Box>

          <Box
            sx={{
              flexGrow: "1",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography component="div" variant="h6">
              Day {selectedDay + 1}
            </Typography>

            {day.items.length > 0 ? (
              <>
                <Typography
                  variant="caption"
                  color="text.secondary"
                  component="div"
                >
                  Totals
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  component="div"
                >
                  {dayTotals.distance +
                    ` ${dayTotals.units} | ` +
                    (dayTotals.hours > 0 ? dayTotals.hours + " hr " : "") +
                    dayTotals.mins +
                    " min"}
                </Typography>
              </>
            ) : (
              <Typography
                variant="body2"
                color="text.secondary"
                component="div"
                sx={{ mt: "10px", mb: "9px" }}
              >
                Day Empty
              </Typography>
            )}
          </Box>
          <Box
            sx={{
              flexGrow: "0",
              display: "flex",
              flexDirection: "column",

              justifyContent: "start",
            }}
          >
            <IconButton
              onClick={handleCloseSelectedDay}
              size="small"
              sx={{ width: "30px", alignSelf: "end" }}
            >
              <CloseIcon fontSize="small"></CloseIcon>
            </IconButton>
            <IconButton
              sx={{ mr: 1 }}
              disabled={selectedDay === itinerary.days.length - 1}
              onClick={() => handleChangeSelectedDay(selectedDay + 1)}
              size="medium"
            >
              <NavigateNextIcon></NavigateNextIcon>
            </IconButton>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}
