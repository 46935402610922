import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import midString from "../util/order";
import { getCognitoSession } from "../auth/cognitoUtils";

const apiUrl = process.env.REACT_APP_BACKEND_API;

// get the cognito id token and returns headers config to attatch to axios requests
const getAuthTokenHeadersConfig = async () => {
  const config = await getCognitoSession()
    .then((session) => {
      return {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${session.idToken.jwtToken}`,
        },
      };
    })
    .catch((err) => {
      throw new Error("No user session found.");
    });
  return config;
};

export const createNewItinerary = createAsyncThunk(
  "itinerary/createItinerary",
  async (initialData) => {
    const initialEmptyItinerary = {
      title: initialData && initialData.title ? initialData.title : "",
      description:
        initialData && initialData.description ? initialData.description : "",
    };

    const config = await getAuthTokenHeadersConfig();

    const response = await axios.post(
      `${apiUrl}/itinerary/create`,
      {
        ...initialEmptyItinerary,
      },
      config
    );

    return response.data.Item;
  }
);

export const getItineraryFromDb = createAsyncThunk(
  "itinerary/getItineraryFromDb",
  async (itineraryId) => {
    const config = await getAuthTokenHeadersConfig();
    const response = await axios.get(
      `${apiUrl}/itinerary/` + itineraryId,
      config
    );
    return response.data;
  }
);

export const updateItineraryInfo = createAsyncThunk(
  "itinerary/update",
  async ({ itineraryId, title, description }) => {
    const config = await getAuthTokenHeadersConfig();

    const response = await axios.patch(
      `${apiUrl}/itinerary/update`,
      {
        itineraryId,
        title,
        description,
      },
      config
    );

    return response.data.Item;
  }
);

export const setColorCodedDaysSaveDb = createAsyncThunk(
  "itinerary/saveColorCodedDaysPref",
  async ({ colorCodedDays }, { dispatch, getState }) => {
    dispatch(setColorCodedDays({ colorCodedDays }));
    const config = await getAuthTokenHeadersConfig();
    const itineraryId = getState().itinerary.data.pk;

    const response = await axios.patch(
      `${apiUrl}/itinerary/set-color-coded-days`,
      {
        itineraryId,
        colorCodedDays,
      },
      config
    );
    return response.data.Item;
  }
);

export const setShowPointLabelsSaveDb = createAsyncThunk(
  "itinerary/setShowPointLabelsSaveDb",
  async ({ showPointLabels }, { dispatch, getState }) => {
    dispatch(setShowPointLabels({ showPointLabels }));
    const config = await getAuthTokenHeadersConfig();
    const itineraryId = getState().itinerary.data.pk;

    const response = await axios.patch(
      `${apiUrl}/itinerary/set-show-point-labels`,
      {
        itineraryId,
        showPointLabels,
      },
      config
    );
    return response.data.Item;
  }
);

export const addDay = createAsyncThunk(
  "itinerary/addDay",
  async (dayIndex, { getState }) => {
    const itineraryId = getState().itinerary.data.pk;
    let newDayIdx = dayIndex;
    if (!dayIndex) {
      newDayIdx = getState().itinerary.data.days.length;
    }

    // get order for new day
    let prev = "";
    let next = "";
    if (newDayIdx > 0) {
      prev = getState().itinerary.data.days[newDayIdx - 1].dayOrder;
    }
    if (newDayIdx < getState().itinerary.data.length) {
      next = getState().itinerary.data.days[newDayIdx].dayOrder;
    }

    const newDayOrder = midString(prev, next);
    // new day object
    const newDay = {
      itineraryId: itineraryId,
      dayOrder: newDayOrder,
      dayDescription: "",
    };

    const config = await getAuthTokenHeadersConfig();

    const response = await axios.post(
      `${apiUrl}/itinerary/create-day`,
      { ...newDay },
      config
    );
    return { item: response.data.Item, index: newDayIdx };
  }
);

export const updateDayColor = createAsyncThunk(
  "itinerary/updateDayColor",
  async ({ dayIndex, color }, { getState }) => {
    const config = await getAuthTokenHeadersConfig();
    const itineraryId = getState().itinerary.data.pk;
    const dayId = getState().itinerary.data.days[dayIndex].dayId;

    const response = await axios.post(
      `${apiUrl}/itinerary/update-day`,
      {
        color,
        dayId,
        itineraryId,
      },
      config
    );

    return { dayIndex, color, response: response.data.Item };
  }
);

export const deleteDay = createAsyncThunk(
  "itinerary/deleteDay",
  async (dayIndex, { getState, dispatch }) => {
    const itineraryId = getState().itinerary.data.pk;
    const dayId = getState().itinerary.data.days[dayIndex].dayId;

    // new day object
    const params = {
      itineraryId: itineraryId,
      dayId: dayId,
    };

    const config = await getAuthTokenHeadersConfig();

    const response = await axios.post(
      `${apiUrl}/itinerary/delete-day`,
      { ...params },
      config
    );
    if (getState().itinerary.data.days[dayIndex].items.length > 0) {
      dispatch(getItineraryFromDb(itineraryId));
      return { dayIndex: -1 };
    } else {
      return { dayIndex };
    }
  }
);

export const addEmptyItineraryItem = createAsyncThunk(
  "itinerary/addEmptyItineraryItem",
  async ({ day, order, index }, { getState }) => {
    const itineraryPk = getState().itinerary.data.pk;
    const newItem = {
      dayId: getState().itinerary.data.days[day].dayId,
      order: order,
      name: "",
      active: false,
      includeInMap: false,
      includeInRouting: false,
      note: "",
    };

    const config = await getAuthTokenHeadersConfig();

    const response = await axios.post(
      `${apiUrl}/items/create-empty-item/` + itineraryPk,
      { ...newItem },
      config
    );

    return { item: response.data.Item, dayIndex: day, index };
  }
);

export const updateItemOrderAsync = createAsyncThunk(
  "itinerary/updateItemOrderAsync",
  async (
    { itemId, newOrder, oldOrder, destDay, sourceDay, destIdx, sourceIdx },
    { getState, dispatch, rejectWithValue }
  ) => {
    const itineraryPk = getState().itinerary.data.pk;

    // we are dispatching to update the item order before we send the update to the db,
    // so that we see the updated order in the system state immediately
    // If we don't do this the drag and drop will switch back to previous order until response is recieved from api
    dispatch(
      updateItemOrder({
        sourceDay,
        sourceIdx,
        newOrder,
        destDay,
        destIdx,
      })
    );

    let sourcePrev, sourceNext, prev, next;

    const days = getState().itinerary.data.days;
    if (days[destDay].items[destIdx].includeInRouting) {
      if (sourceDay === destDay && destIdx < sourceIdx) {
        sourcePrev = getPrevGeo(sourceDay, sourceIdx + 1, days);
        sourceNext = getNextGeo(sourceDay, sourceIdx, days);
      } else {
        sourcePrev = getPrevGeo(sourceDay, sourceIdx, days);
        sourceNext = getNextGeo(sourceDay, sourceIdx - 1, days);
      }
      prev = getPrevGeo(destDay, destIdx, days);
      next = getNextGeo(destDay, destIdx, days);
    }
    const props = {
      itineraryId: itineraryPk,
      itemId: itemId,
      dayId: getState().itinerary.data.days[destDay].dayId,
      order: newOrder,
      sourcePrev,
      sourceNext,
      prev,
      next,
    };

    let response;
    try {
      const config = await getAuthTokenHeadersConfig();
      // try to send the update
      response = await axios.patch(
        `${apiUrl}/items/update-item-order`,
        {
          ...props,
        },
        config
      );
    } catch (e) {
      // if the update fails we use thunkAPI rejectWithValue to send items previous location to the rejected reducer
      return rejectWithValue({
        oldOrder,
        sourceDay,
        sourceIdx,
        newOrder,
        destDay,
        destIdx,
        message: e.response.data.message,
      });
    }
    // we will need to update route if reordered item is in the route
    return {
      item: response.data.item,
      routeSource: response.data.source,
      routeCurrent: response.data.current,
      routeNext: response.data.next,
      sourcePrev,
      sourceNext,
      prev,
      next,
      day: destDay,
      index: destIdx,
    };
  }
);

export const getGeocodeDataForItem = createAsyncThunk(
  "itinerary/getGeocode",
  async (
    { itemId, geo, day, index, isFlight },
    { getState, dispatch, rejectWithValue }
  ) => {
    const days = getState().itinerary.data.days;
    const prev = getPrevGeo(day, index, days);
    const next = getNextGeo(day, index, days);

    let wikiSearch;
    let wikiData;
    if (geo.result_type === "state") {
      wikiSearch = geo.state + ", " + geo.country;
    } else if (geo.result_type === "county") {
      wikiSearch = geo.county + ", " + geo.state;
    } else if (geo.result_type === "city") {
      wikiSearch =
        geo.city +
        ", " +
        (geo.country_code === "us" || geo.country_code === "ca"
          ? geo.state
          : geo.country);
    } else if (geo.result_type === "amenity") {
      wikiSearch = geo.address_line1;
    }
    if (wikiSearch) {
      try {
        const wikiResponse = await axios.get(
          `https://api.wikimedia.org/core/v1/wikipedia/en/search/title?q=${wikiSearch}&limit=1`
        );
        if (wikiResponse && wikiResponse.data.pages.length > 0) {
          wikiData = wikiResponse.data.pages[0];
        }
      } catch (e) {}
    }

    const config = await getAuthTokenHeadersConfig();
    try {
      const response = await axios.patch(
        `${apiUrl}/items/update-item-geo`,
        {
          itemId,
          geo,
          prev,
          next,
          isFlight,
          wiki: wikiData,
        },
        config
      );
      return {
        itemId,
        day,
        index,
        prev,
        next,
        routeCurrent: response.data.current,
        routeNext: response.data.next,
        geo: response.data.item.geo,
        isFlight,
        wiki: wikiData,
      };
    } catch (e) {
      return rejectWithValue({ day, index, message: e.response.data.message });
    }
  }
);

export const updateRouteMode = createAsyncThunk(
  "itinerary/updateRouteMode",
  async (
    { itemId, setIsFlight, day, index },
    { getState, rejectWithValue }
  ) => {
    const days = getState().itinerary.data.days;
    const prev = getPrevGeo(day, index, days);

    const config = await getAuthTokenHeadersConfig();

    try {
      const response = await axios.patch(
        `${apiUrl}/items/update-mode`,
        {
          itemId,
          setIsFlight,
          prev,
        },
        config
      );

      return {
        itemId,
        day,
        index,
        isFlight: response.data.isFlight,
        route: response.data.route,
      };
    } catch (e) {
      return rejectWithValue({ message: e.response.data.message });
    }
  }
);

export const deleteItemAsync = createAsyncThunk(
  "itinerary/deleteItemAsync",
  async ({ itemId, day, index }, { getState, dispatch, rejectWithValue }) => {
    const days = getState().itinerary.data.days;
    let prev, next;

    if (getState().itinerary.data.days[day].items[index].includeInRouting) {
      prev = getPrevGeo(day, index, days);
      next = getNextGeo(day, index, days);
    }

    const itemDeleted = getState().itinerary.data.days[day].items[index];
    dispatch(deleteItem({ day, index, prev, next }));

    const config = await getAuthTokenHeadersConfig();

    const body = {
      itemId,
      prev,
      next,
    };
    try {
      const response = await axios.post(
        `${apiUrl}/items/delete-item/`,
        body,
        config
      );
      return { ...response.data, prev, next, day, index };
    } catch (e) {
      return rejectWithValue({
        message: e.response.data.message,
        day,
        index,
        itemId,
        itemDeleted,
      });
    }
  }
);

const getPrevGeo = (day, index, days) => {
  for (let i = day; i >= 0; i--) {
    const jValue = day === i ? index - 1 : days[i].items.length - 1;

    for (let j = jValue; j >= 0; j--) {
      if (days[i].items[j].includeInRouting) {
        return {
          lat: days[i].items[j].geo.lat,
          lon: days[i].items[j].geo.lon,
          itemId: days[i].items[j].itemId,
          day: i,
          index: j,
          isFlight: days[i].items[j].isFlight,
        };
      }
    }
  }
  return;
};

const getNextGeo = (day, index, days) => {
  for (let i = day; i < days.length; i++) {
    const jValue = day === i ? index + 1 : 0;

    for (let j = jValue; j < days[i].items.length; j++) {
      if (days[i].items[j].includeInRouting) {
        return {
          lat: days[i].items[j].geo.lat,
          lon: days[i].items[j].geo.lon,
          itemId: days[i].items[j].itemId,
          day: i,
          index: j,
          isFlight: days[i].items[j].isFlight,
        };
      }
    }
  }
  return;
};

// initial state
const initialState = {
  data: {
    pk: "",

    title: "",
    description: "",
    days: [
      {
        dayOrder: "g",
        dayDescription: "",
        items: [],
      },
    ],
    colorCodedDays: true,
    showPointLabels: true,
    selectedRouteItem: null,
    selectedDay: null,
    popup: null,
  },
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  routeStatus: "idle",
  routeError: null,
};

const itinerarySlice = createSlice({
  name: "itinerary",
  initialState,
  reducers: {
    updateItemOrder: (state, action) => {
      // We are moving the item to its new location even though we haven't sent/recieved a response
      // if we don't do this the drag and drop will display the previous order until response is recieved
      // see the updateItemOrderAsync thunk above
      const {
        sourceDay,
        sourceIdx,
        newOrder,
        destDay,
        destIdx,
        sourcePrev,
        prev,
      } = action.payload;

      const item = state.data.days[sourceDay].items[sourceIdx];
      item.order = newOrder;
      item.dayId = destDay;
      state.data.days[sourceDay].items.splice(sourceIdx, 1);
      state.data.days[destDay].items.splice(destIdx, 0, item);
    },
    deleteItem: (state, action) => {
      // We are moving the item to its new location even though we haven't sent/recieved a response
      // if we don't do this the drag and drop will display the previous order until response is recieved
      // see the updateItemOrderAsync thunk above
      const { day, index, prev, next } = action.payload;
      if (!prev && next) {
        delete state.data.days[next.day].items[next.index].route;
      }
      state.data.days[day].items.splice(index, 1);
    },
    setColorCodedDays: (state, action) => {
      const { colorCodedDays } = action.payload;
      state.data.colorCodedDays = colorCodedDays;
    },
    setShowPointLabels: (state, action) => {
      const { showPointLabels } = action.payload;
      state.data.showPointLabels = showPointLabels;
    },
    setSelectedRouteItem: (state, action) => {
      const { item } = action.payload;
      state.data.selectedRouteItem = item;
    },
    setSelectedDay: (state, action) => {
      const { day } = action.payload;
      state.data.selectedDay = day;
    },
    setPopup: (state, action) => {
      const { item } = action.payload;
      state.data.popup = item;
    },
    addItemCompleteWikiData: (state, action) => {
      const { fullWiki, day, index } = action.payload;
      state.data.days[day].items[index].fullWiki = fullWiki;
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(createNewItinerary.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(createNewItinerary.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(createNewItinerary.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updateItineraryInfo.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(updateItineraryInfo.fulfilled, (state, action) => {
        state.status = "succeeded";
        //state.data.title = action.payload;
        //state.data.description = action.payload;
      })
      .addCase(updateItineraryInfo.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(setColorCodedDaysSaveDb.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(setColorCodedDaysSaveDb.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(setColorCodedDaysSaveDb.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(setShowPointLabelsSaveDb.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(setShowPointLabelsSaveDb.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(setShowPointLabelsSaveDb.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(getItineraryFromDb.pending, (state, action) => {
        state.status = "loading";
        state.routeStatus = "loading";
      })
      .addCase(getItineraryFromDb.fulfilled, (state, action) => {
        const data = action.payload;
        state.data = data;
        state.data.selectedRouteItem = null;
        state.data.selectedDay = null;
        state.data.popup = null;
        state.status = "succeeded";
        state.routeStatus = "succeeded";
      })
      .addCase(getItineraryFromDb.rejected, (state, action) => {
        state.status = "failed";
        //state.error = action.error.message;
        state.error = "Error Loading Itinerary";
        state.routeStatus = "failed";
        //state.routeError = action.error.message;
        state.routeError = "Error Loading Itinerary";
      })
      .addCase(addEmptyItineraryItem.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(addEmptyItineraryItem.fulfilled, (state, action) => {
        const { item, index, dayIndex } = action.payload;

        if (!action.payload.index) {
          state.data.days[dayIndex].items.push(item);
        } else {
          state.data.days[dayIndex].items.splice(index, 0, item);
        }

        state.status = "succeeded";
      })
      .addCase(addEmptyItineraryItem.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addDay.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(addDay.fulfilled, (state, action) => {
        const { index, item } = action.payload;
        state.data.days.splice(index, 0, { ...item, items: [] });
        state.status = "succeeded";
      })
      .addCase(addDay.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updateDayColor.pending, (state, action) => {
        state.status = "loading";
        state.routeStatus = "loading";
      })
      .addCase(updateDayColor.fulfilled, (state, action) => {
        const { dayIndex, color } = action.payload;

        state.data.days[dayIndex].color = color;
        state.status = "succeeded";
        state.routeStatus = "succeeded";
      })
      .addCase(updateDayColor.rejected, (state, action) => {
        state.status = "failed";
        state.routeStatus = "failed";
        state.error = action.error.message;
      })
      .addCase(deleteDay.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(deleteDay.fulfilled, (state, action) => {
        const { dayIndex } = action.payload;
        if (dayIndex >= 0) {
          state.data.days.splice(dayIndex, 1);
        }
        state.status = "succeeded";
      })
      .addCase(deleteDay.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updateItemOrderAsync.pending, (state, action) => {
        state.status = "loading";
        state.routeStatus = "loading";
      })
      .addCase(updateItemOrderAsync.fulfilled, (state, action) => {
        const {
          routeCurrent,
          routeNext,
          routeSource,
          sourcePrev,
          sourceNext,
          prev,
          next,
          day,
          index,
        } = action.payload;
        if (state.data.days[day].items[index].includeInRouting) {
          if (!sourcePrev) {
            delete state.data.days[sourceNext.day].items[sourceNext.index]
              .route;
          } else if (!prev) {
            delete state.data.days[day].items[index].route;
          }

          if (routeSource) {
            state.data.days[sourceNext.day].items[sourceNext.index] = {
              ...state.data.days[sourceNext.day].items[sourceNext.index],
              route: {
                time: routeSource.time,
                distance: routeSource.distance,
                geojson: routeSource.geojson,
              },
            };
          }
          if (routeCurrent) {
            state.data.days[day].items[index] = {
              ...state.data.days[day].items[index],
              route: {
                time: routeCurrent.time,
                distance: routeCurrent.distance,
                geojson: routeCurrent.geojson,
              },
            };
          }
          if (routeNext) {
            state.data.days[next.day].items[next.index] = {
              ...state.data.days[next.day].items[next.index],
              route: {
                time: routeNext.time,
                distance: routeNext.distance,
                geojson: routeNext.geojson,
              },
            };
          }
        }
        state.status = "succeeded";
        state.routeStatus = "succeeded";
      })
      .addCase(updateItemOrderAsync.rejected, (state, action) => {
        // if update call to db fails we move the item back to it's original location
        state.status = "failed";
        state.error = action.payload.message;
        state.routeStatus = "failed";
        const { sourceDay, sourceIdx, oldOrder, destDay, destIdx } =
          action.payload;
        const item = state.data.days[destDay].items[destIdx];
        item.order = oldOrder;
        item.dayId = destDay;
        state.data.days[destDay].items.splice(destIdx, 1);
        state.data.days[sourceDay].items.splice(sourceIdx, 0, item);
      })
      .addCase(deleteItemAsync.pending, (state, action) => {
        state.status = "loading";
        state.routeStatus = "loading";
      })
      .addCase(deleteItemAsync.fulfilled, (state, action) => {
        const { route, prev, next, day, index } = action.payload;

        if (prev && next) {
          if (next.day === day) {
            state.data.days[day].items[index] = {
              ...state.data.days[day].items[index],
              route: {
                time: route.time,
                distance: route.distance,
                geojson: route.geojson,
              },
            };
          } else {
            state.data.days[next.day].items[next.index] = {
              ...state.data.days[next.day].items[next.index],
              route: {
                time: route.time,
                distance: route.distance,
                geojson: route.geojson,
              },
            };
          }
        }
        state.status = "succeeded";
        state.routeStatus = "succeeded";
      })
      .addCase(deleteItemAsync.rejected, (state, action) => {
        const { itemDeleted, day, index } = action.payload;
        state.status = "failed";
        state.routeStatus = "failed";
        state.error = action.payload.message;
        state.routeError = action.payload.message;

        state.data.days[day].items.splice(index, 0, itemDeleted);
      })
      .addCase(getGeocodeDataForItem.pending, (state, action) => {
        state.status = "loading";
        state.routeStatus = "loading";
        const { day, index } = action.meta.arg;
        state.data.days[day].items[index] = {
          ...state.data.days[day].items[index],
          isItemLoadingGeo: true,
        };
      })
      .addCase(getGeocodeDataForItem.fulfilled, (state, action) => {
        const {
          itemId,
          day,
          index,
          next,
          routeNext,
          routeCurrent,
          geo,
          isFlight,
          wiki,
        } = action.payload;

        if (state.data.days[day].items[index].itemId === itemId) {
          state.data.days[day].items[index].includeInMap = true;
          state.data.days[day].items[index].includeInRouting = true;
          state.data.days[day].items[index].geo = geo;
          state.data.days[day].items[index].isFlight = isFlight;
          state.data.days[day].items[index].wiki = wiki;

          if (routeCurrent) {
            state.data.days[day].items[index] = {
              ...state.data.days[day].items[index],
              route: {
                time: routeCurrent.time,
                distance: routeCurrent.distance,
                geojson: routeCurrent.geojson,
              },
            };
          }
          if (routeNext) {
            state.data.days[next.day].items[next.index] = {
              ...state.data.days[next.day].items[next.index],
              route: {
                time: routeNext.time,
                distance: routeNext.distance,
                geojson: routeNext.geojson,
              },
            };
          }
        }
        delete state.data.days[day].items[index].isItemLoadingGeo;
        state.status = "succeeded";
        state.routeStatus = "succeeded";
      })
      .addCase(getGeocodeDataForItem.rejected, (state, action) => {
        state.status = "failed";
        state.routeStatus = "failed";
        console.error(action.payload);
        state.error = action.payload.message;

        const { day, index } = action.payload;
        delete state.data.days[day].items[index].isItemLoadingGeo;
      })
      .addCase(updateRouteMode.pending, (state, action) => {
        state.status = "loading";
        state.routeStatus = "loading";
      })
      .addCase(updateRouteMode.fulfilled, (state, action) => {
        const { day, index, isFlight, route } = action.payload;
        state.data.days[day].items[index].isFlight = isFlight;
        state.data.days[day].items[index].route = route;

        state.status = "succeeded";
        state.routeStatus = "succeeded";
      })
      .addCase(updateRouteMode.rejected, (state, action) => {
        state.status = "failed";
        state.routeStatus = "failed";
        console.error("update mode error");
        console.error(action);
        state.error = action.payload.message;
      });
  },
});

export const getItinerary = (state) => state.itinerary.data;
export const getColorCodedDaysPref = (state) =>
  state.itinerary.data.colorCodedDays;
export const getShowPointLabels = (state) =>
  state.itinerary.data.showPointLabels;
export const getSelectedRouteItem = (state) =>
  state.itinerary.data.selectedRouteItem;
export const getSelectedDay = (state) => state.itinerary.data.selectedDay;
export const getPopup = (state) => state.itinerary.data.popup;
export const getItineraryStatus = (state) => state.itinerary.status;
export const getItineraryError = (state) => state.itinerary.error;
export const getItineraryRouteStatus = (state) => state.itinerary.routeStatus;

export const {
  updateItemOrder,
  deleteItem,
  setColorCodedDays,
  setShowPointLabels,
  setSelectedRouteItem,
  setSelectedDay,
  setPopup,
  addItemCompleteWikiData,
} = itinerarySlice.actions;

export default itinerarySlice.reducer;
