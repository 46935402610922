import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Container } from "@mui/material";
import { useAuth } from "./AuthContext";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { createAlert } from "../redux/alert";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useState } from "react";

import { LoadingButton } from "@mui/lab";

export default function VerifyEmail({ getUserDetails }) {
  const dispatch = useDispatch();
  const { getSession } = useAuth();
  const formValidationSchema = Yup.object().shape({
    code: Yup.string()
      .required("Please enter the confirmation code sent to your email")
      .matches(/^[0-9]{6,6}$/, "Invalid confirmation code"),
  });

  const {
    register,
    reset,
    handleSubmit,
    setValue,
    formState: { errors, dirtyFields, isDirty, isValid },
  } = useForm({
    resolver: yupResolver(formValidationSchema, {
      validateAllFieldCriteria: true,
    }),
    criteriaMode: "all",
    mode: "onChange",
  });

  const { state } = useLocation();

  const [loading, setLoading] = useState(false);

  const handleConfirm = (formData) => {
    setLoading(true);
    reset(formData);
    getSession().then(({ user }) => {
      user.verifyAttribute("email", formData.code, {
        onSuccess: () => {
          dispatch(
            createAlert({
              message: "Email Confirmed",
              type: "success",
            })
          );
          getUserDetails();
          setLoading(false);
        },
        onFailure: (e) => {
          dispatch(createAlert({ message: e.message, type: "error" }));
          setLoading(false);
        },
      });
    });
  };

  const handleResendCode = () => {
    reset();

    getSession().then((user) => {
      user.user.getAttributeVerificationCode("email", {
        onSuccess: () => {
          dispatch(
            createAlert({
              message: "A new code has been sent to " + user.email,
              type: "info",
            })
          );
        },
        onFailure: (e) => {
          dispatch(createAlert({ message: e.message, type: "error" }));
        },
      });
    });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box component="form" noValidate onSubmit={handleSubmit(handleConfirm)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                component="h1"
                variant="body2"
                sx={{ textAlign: "center" }}
              >
                The email address associated with your account has changed and
                needs to be verified. Please enter the code that was sent to the
                new email.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="code"
                label="Code"
                id="code"
                autoComplete="off"
                {...register("code")}
                error={!!errors?.code}
                helperText={errors.code ? errors.code.message : " "}
                type="text"
              />
            </Grid>
          </Grid>
          <LoadingButton
            type="submit"
            fullWidth
            variant="contained"
            loading={loading}
            disabled={!isDirty || !isValid}
            sx={{ mt: 1, mb: 2 }}
          >
            Next
          </LoadingButton>
        </Box>

        <Link
          component="button"
          variant="body2"
          onClick={() => {
            handleResendCode();
          }}
        >
          Resend confirmation code
        </Link>
      </Box>
    </Container>
  );
}
