import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TripMapper from "./trip-mapper/TripMapper";
import { Routes, Route, Navigate } from "react-router-dom";
import Landing from "./Landing";
import { MapProvider } from "react-map-gl";
import SignUp from "./auth/SignUp";
import SignIn from "./auth/SignIn";
import ManageAccount from "./auth/ManageAccount";
import AlertSnackbar from "./AlertSnackbar";
import AuthenticatedRoute from "./AuthenticatedRoute";
import UnauthenticatedRoute from "./UnauthenticatedRoute";
import ForgotPassword from "./auth/ForgotPassword";
import ConfirmAccount from "./auth/ConfirmAccount";
import Itineraries from "./Itineraries";
import { ConfirmProvider } from "material-ui-confirm";
//import "maplibre-gl/dist/maplibre-gl.css";

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#506646",
        light: "#7d9472",
        dark: "#273b1e",
      },
      secondary: {
        main: "#455a64",
        light: "#718792",
        dark: "#1c313a",
      },
      neutral: {
        main: "#506646",
        contrastText: "#fff",
      },
    },
    typography: {
      fontFamily: ["-apple-system", "Inter", "Helvetica", "Arial"].join(","),
    },
  });

  return (
    <>
      <ThemeProvider theme={theme}>
        <ConfirmProvider>
          <Routes>
            <Route path="/" element={<Navigate to="itineraries" replace />} />
            <Route
              path="itineraries"
              element={
                <AuthenticatedRoute>
                  <Itineraries />
                </AuthenticatedRoute>
              }
            />
            {/* <Route path=":itineraryId" element={<TripViewer />} /> */}
            <Route
              path="edit/:itineraryId"
              element={
                <MapProvider>
                  <TripMapper />
                </MapProvider>
              }
            />

            <Route
              path="register"
              element={
                <UnauthenticatedRoute>
                  <SignUp />
                </UnauthenticatedRoute>
              }
            />
            <Route
              path="confirm"
              element={
                <UnauthenticatedRoute>
                  <ConfirmAccount />
                </UnauthenticatedRoute>
              }
            />
            <Route
              path="login"
              element={
                <UnauthenticatedRoute>
                  <SignIn />
                </UnauthenticatedRoute>
              }
            />
            <Route
              path="forgot-password"
              element={
                <UnauthenticatedRoute>
                  <ForgotPassword />
                </UnauthenticatedRoute>
              }
            />
            <Route
              path="account"
              element={
                <AuthenticatedRoute>
                  <ManageAccount />
                </AuthenticatedRoute>
              }
            />
          </Routes>
        </ConfirmProvider>
        <AlertSnackbar />
      </ThemeProvider>
    </>
  );
}

export default App;
