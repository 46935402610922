import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Link } from "@mui/material";
import Button from "@mui/material/Button";
import PlaceIcon from "@mui/icons-material/Place";
import AccountButton from "./auth/AccountButton";
import { useAuth } from "./auth/AuthContext";

const pages = [];

const NavBar = () => {
  const { currentUser } = useAuth();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="sticky">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <PlaceIcon sx={{ display: { xs: "none", md: "flex" }, mr: 1 }} />
          <Typography
            variant="h5"
            noWrap
            component="a"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".2rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            Maptinerary
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" }, mr: 1 }}>
            {pages.map((page) => (
              <Button
                key={page}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                {page}
              </Button>
            ))}
          </Box>
          {currentUser ? (
            <AccountButton />
          ) : (
            <Box sx={{ flexGrow: 0, display: { xs: "flex", md: "flex" } }}>
              <Link
                href="/login"
                underline="none"
                sx={{ display: { xs: "flex", md: "flex" } }}
              >
                <Button
                  color="secondary"
                  variant="contained"
                  sx={{ my: 2, color: "white", display: "block", mr: 1 }}
                >
                  Login
                </Button>
              </Link>
              <Link
                href="/register"
                underline="none"
                sx={{ display: { xs: "none", md: "flex" } }}
              >
                <Button sx={{ my: 2, color: "white", display: "block" }}>
                  Sign Up
                </Button>
              </Link>
            </Box>
          )}
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "flex", md: "none" },
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <PlaceIcon fontSize="small" sx={{ mr: 1 }} />
            <Typography
              variant="body1"
              noWrap
              component="a"
              href=""
              sx={{
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".2rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              Maptinerary
            </Typography>
          </Box>
          <Box
            sx={{
              flexGrow: 0,
              display: { xs: "flex", md: "none" },
              width: "45px",
            }}
          ></Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default NavBar;
