import React from "react";
import {
  Collapse,
  Typography,
  Box,
  Divider,
  Link,
  Avatar,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import InfoIcon from "@mui/icons-material/Info";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import PlaceIcon from "@mui/icons-material/Place";
import PublicIcon from "@mui/icons-material/Public";
import ReactCountryFlag from "react-country-flag";

export default function InfoExpand({ item, day, index }) {
  const [expanded, setExpanded] = React.useState(false);

  return (
    <Box
      sx={{
        width: "100%",
        mr: "22px",
      }}
    >
      <Collapse in={expanded}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "secondary",
            justifyContent: "start",
            alignItems: "start",
            textAlign: "left",
            width: "100%",
          }}
        >
          <Divider sx={{ width: "100%", mb: 2, mt: 1 }}></Divider>
          <Box
            sx={{
              width: "100%",
              display: "grid",
              gap: 1,
              gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "secondary",
                justifyContent: "start",
                alignItems: "start",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "start",
                  alignItems: "center",
                  mb: 1,
                }}
              >
                <PublicIcon
                  fontSize="small"
                  color="primary"
                  sx={{
                    mr: 1,
                  }}
                ></PublicIcon>
                <Typography color="text.primary" variant="body2" sx={{ mr: 1 }}>
                  {item.geo.state ? item.geo.state + ", " : ""}
                  {item.geo.country}
                </Typography>
                <ReactCountryFlag
                  svg
                  style={{
                    width: "1.5em",
                    height: "1.25em",
                  }}
                  countryCode={item.geo.country_code}
                  aria-label={item.geo.country}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "start",
                  alignItems: "center",
                  mb: 1,
                }}
              >
                <MyLocationIcon
                  fontSize="small"
                  color="primary"
                  sx={{
                    mr: 1,
                  }}
                ></MyLocationIcon>
                <Typography color="text.primary" variant="body2">
                  {item.geo.lat}, {item.geo.lon}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "start",
                  alignItems: "center",
                  mb: 1,
                }}
              >
                <AccessTimeIcon
                  fontSize="small"
                  color="primary"
                  sx={{
                    mr: 1,
                  }}
                ></AccessTimeIcon>
                <Typography color="text.primary" variant="body2">
                  {item.geo.timezone.name.replace("_", " ")} (
                  {item.geo.timezone.abbreviation_STD})
                </Typography>
              </Box>

              {item.wiki && (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "start",
                      alignItems: "center",
                      mb: 1,
                    }}
                  >
                    <InfoIcon
                      fontSize="small"
                      color="primary"
                      sx={{
                        mr: 1,
                      }}
                    ></InfoIcon>
                    <Typography color="text.primary" variant="body2">
                      {item.wiki.description}
                    </Typography>
                  </Box>
                </>
              )}
            </Box>

            {item.wiki && (
              <Avatar
                src={item.wiki?.thumbnail?.url}
                sx={{
                  width: 125,
                  height: 125,
                  justifySelf: "center",
                  mb: 2,
                  mr: "25px",
                }}
              >
                <PlaceIcon></PlaceIcon>
              </Avatar>
            )}
          </Box>
        </Box>
        {item.wiki && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "end",
              width: "100%",
            }}
          >
            <Link
              href={`https://en.wikipedia.org/wiki/${item.wiki.key}`}
              target="_blank"
              rel="noopener noreferrer"
              variant="body2"
              sx={{ textAlign: "right" }}
            >
              {`More About ${item.wiki.title} On Wikipedia`}
            </Link>
            <Link
              href={`https://en.wikipedia.org/wiki/${item.wiki.key}`}
              target="_blank"
              rel="noopener noreferrer"
              variant="body2"
              sx={{ textAlign: "right" }}
            >
              <OpenInNewIcon
                fontSize="small"
                color="primary"
                sx={{
                  ml: 1,
                  mr: 1,
                }}
              ></OpenInNewIcon>
            </Link>
          </Box>
        )}
      </Collapse>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
        }}
      >
        {expanded ? (
          <ExpandLessIcon
            fontSize="medium"
            onClick={() => setExpanded(!expanded)}
            sx={{ alignSelf: "center", flexGrow: 1 }}
          ></ExpandLessIcon>
        ) : (
          <ExpandMoreIcon
            fontSize="medium"
            onClick={() => setExpanded(!expanded)}
            sx={{ alignSelf: "center", flexGrow: 1 }}
          ></ExpandMoreIcon>
        )}
        <Box sx={{ minWidth: "20px", flexGrow: 0 }}></Box>
      </Box>
    </Box>
  );
}
