import React from "react";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import {
  getItineraryRouteStatus,
  getItineraryStatus,
} from "../../../redux/itinerary";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import Address from "./new-item/Address";

export default function EditItemLocation({ item, index, dayIndex }) {
  const itineraryStatus = useSelector(getItineraryStatus);
  const routeStatus = useSelector(getItineraryRouteStatus);

  return (
    <Box sx={{ display: "flex", py: "10px", px: "10px" }}>
      <DragIndicatorIcon
        sx={{ fontSize: "1.5em", flexGrow: 0, alignSelf: "center" }}
        color={
          routeStatus === "loading" || itineraryStatus === "loading"
            ? "disabled"
            : "action"
        }
      />

      <Address
        sx={{ flexGrow: 1 }}
        item={item}
        index={index}
        dayIndex={dayIndex}
      ></Address>
    </Box>
  );
}
