import * as React from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import YupPassword from "yup-password";
import { countries } from "../util/countries";
import { timezones } from "../util/timezones";
import InputAdornment from "@mui/material/InputAdornment";
import { Controller } from "react-hook-form";
import { createAlert } from "../redux/alert";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import PublicIcon from "@mui/icons-material/Public";
import { MenuItem } from "@mui/material";
import ReactCountryFlag from "react-country-flag";
import { useAuth } from "./AuthContext";
import { LoadingButton } from "@mui/lab";

YupPassword(Yup); // extend yup

export default function ChangeAttributes({}) {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { getSession, authenticate, logout, currentUser } = useAuth();

  const [currentAttributes, setCurrentAttribute] = React.useState();
  const [loading, setLoading] = React.useState(false);
  useEffect(() => {
    getAttributes();
  }, []);

  const getAttributes = async () => {
    getSession().then((user) => {
      const country = countries.find((c) => c.code === user["custom:country"]);
      setCurrentAttribute({
        country,
        timezone: user["custom:timezone"],
        distanceUnits: user["custom:distanceUnits"],
      });
    });
  };

  useEffect(() => {
    reset(currentAttributes);
  }, [currentAttributes]);

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option) => option.label + " " + option.code + " " + option.alt,
  });

  const formValidationSchema = Yup.object().shape({
    country: Yup.object()
      .required("Country is required")
      .nullable("please select a country"),
    // distanceUnits: Yup.string().required("Password is required").length(2),
    timezone: Yup.string().required().nullable(),
    distanceUnits: Yup.string().required().nullable(),
  });

  const {
    register,
    reset,
    setValue,
    getValues,
    control,
    handleSubmit,
    formState: { errors, dirtyFields, isDirty, isValid },
  } = useForm({
    resolver: yupResolver(formValidationSchema, {
      validateAllFieldCriteria: true,
    }),
    criteriaMode: "all",
    mode: "onChange",
  });

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const handleUpdate = (formData) => {
    setLoading(true);
    getSession().then(({ user }) => {
      user.updateAttributes(
        [
          {
            Name: "custom:country",
            Value: formData.country.code,
          },
          {
            Name: "custom:timezone",
            Value: formData.timezone,
          },
          {
            Name: "custom:distanceUnits",
            Value: formData.distanceUnits,
          },
        ],
        (e) => {
          if (e) {
            getAttributes();
            dispatch(createAlert({ message: e.message, type: "error" }));
          } else {
            reset(formData);
          }
          setLoading(false);
        }
      );
    });
  };

  return (
    <Box component="form" onSubmit={handleSubmit(handleUpdate)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            defaultValue={null}
            render={({ field }) => (
              <Autocomplete
                {...field}
                id="country"
                options={countries}
                autoHighlight
                filterOptions={filterOptions}
                isOptionEqualToValue={(option, value) => {
                  return option.label === value.label;
                }}
                getOptionLabel={(option) => option.label}
                renderOption={(props, option) => (
                  <Box {...props}>{option.label}</Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Country"
                    variant="outlined"
                    required
                    error={!!errors?.country}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <>
                          <InputAdornment
                            sx={{ ml: 1, width: "24px" }}
                            position="start"
                          >
                            {field.value?.code ? (
                              <ReactCountryFlag
                                svg
                                countryCode={field.value.code}
                                style={{
                                  width: "1.75em",
                                  height: "1em",
                                }}
                              />
                            ) : (
                              <PublicIcon></PublicIcon>
                            )}
                          </InputAdornment>
                          {params.InputProps.startAdornment}
                        </>
                      ),

                      autoComplete: "new-password",
                      // disable autocomplete and autofill
                    }}
                  />
                )}
                onChange={(_, data) => field.onChange(data)}
              />
            )}
            name="country"
            control={control}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="timezone"
            control={control}
            defaultValue={null}
            render={({ field }) => (
              <Autocomplete
                {...field}
                id="timezone"
                autoHighlight
                options={timezones}
                getOptionLabel={(option) => option.replaceAll("_", " ")}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    <Typography>{option.replaceAll("_", " ")}</Typography>
                  </Box>
                )}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      label="Timezone"
                      required
                      error={!!errors?.timezone}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        ...params.InputProps,
                        autoComplete: "new-password",
                        // disable autocomplete and autofill
                      }}
                    />
                  );
                }}
                onChange={(_, data) => {
                  field.onChange(data);
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            defaultValue={"K"}
            render={({ field }) => (
              <TextField fullWidth select label="Distance Units" {...field}>
                <MenuItem value={"M"}>Miles</MenuItem>
                <MenuItem value={"K"}>Kilometers</MenuItem>
              </TextField>
            )}
            name="distanceUnits"
            control={control}
          />
        </Grid>
      </Grid>
      <LoadingButton
        type="submit"
        fullWidth
        disabled={!isDirty || !isValid}
        loading={loading}
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        {!isDirty || !isValid ? "Updated" : "Update Changes"}
      </LoadingButton>
    </Box>
  );
}
