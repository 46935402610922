import React from "react";

import PlaceIcon from "@mui/icons-material/Place";
import { Box, Typography, useTheme } from "@mui/material";

export default function Logo() {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <PlaceIcon color="primary" />
      <Typography
        variant="h5"
        color="primary"
        noWrap
        sx={{
          ml: 1,
          fontFamily: "monospace",
          fontWeight: 900,
          letterSpacing: ".2rem",
        }}
      >
        Maptinerary
      </Typography>
    </Box>
  );
}
