import React from "react";
import {
  Typography,
  Tooltip,
  IconButton,
  Box,
  Button,
  Menu,
  MenuItem,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch } from "react-redux";
import { deleteDay } from "../../../redux/itinerary";

export default function DayMenu({ dayIndex }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const dispatch = useDispatch();
  const handleClickDeleteDay = () => {
    dispatch(deleteDay(dayIndex));
    handleClose();
  };
  return (
    <>
      <Tooltip title={`Day Options`} enterDelay={500} placement="left">
        <IconButton onClick={handleClick}>
          <MoreHorizIcon></MoreHorizIcon>
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={handleClickDeleteDay}>
          <Typography color="error">Delete Day</Typography>
          <DeleteIcon
            color="error"
            fontSize="small"
            sx={{ ml: 1 }}
          ></DeleteIcon>
        </MenuItem>
      </Menu>
    </>
  );
}
