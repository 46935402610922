import { Typography, Box } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { getItinerary } from "../../redux/itinerary";
import ItineraryList from "./ItineraryList";
import styled from "styled-components";
import ItinerarySettings from "./ItinerarySettings";
import { useAuth } from "../../auth/AuthContext";

export default function Itinerary() {
  const itinBarHeightPx = "56px";
  const itinerary = useSelector(getItinerary);
  const { currentUser } = useAuth();

  const itineraryTotals = React.useMemo(() => {
    let tTotal = 0;
    let dTotal = 0;
    for (let i = 0; i < itinerary.days.length; i++) {
      for (let j = 0; j < itinerary.days[i].items.length; j++) {
        const item = itinerary.days[i].items[j];
        if (item.includeInRouting && item.route) {
          dTotal += item.route.distance;
          tTotal += item.route.time;
        }
      }
    }

    // if user preference is miles convert
    let units = "km";
    if (currentUser.distanceUnits === "M") {
      dTotal *= 0.6213712;
      units = "mi";
    }

    const distance = Math.round(dTotal / 100) / 10;
    const totalMins = Math.round(tTotal / 60);
    const hours = Math.floor(totalMins / 60);
    const mins = Math.floor(totalMins % 60);

    return { distance, units, totalMins, hours, mins };
  }, [itinerary.days, currentUser]);
  return (
    <>
      <ItineraryHeaderStyle>
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <ItinerarySettings></ItinerarySettings>

          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: itinBarHeightPx,
            }}
          >
            <Typography sx={{ pt: "3px", fontSize: "20px", fontWeight: "600" }}>
              {itinerary.title}
            </Typography>
            <Typography
              sx={{ pb: "6px", fontSize: "11px", fontWeight: "500" }}
              color="text.secondary"
            >
              {itineraryTotals.distance +
                ` ${itineraryTotals.units} | ` +
                (itineraryTotals.hours > 0
                  ? itineraryTotals.hours + " hr "
                  : "") +
                itineraryTotals.mins +
                " min"}
            </Typography>
          </Box>
          <Box
            sx={{
              width: "60px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          ></Box>
          {/* <ToggleButtonGroup
              size="small"
              value={itineraryView}
              exclusive
              onChange={handleItineraryView}
              aria-label="text alignment"
            >
              <ToggleButton value="list" aria-label="left aligned">
                <TableRowsIcon />
              </ToggleButton>
              <ToggleButton value="grid" aria-label="centered">
                <ViewComfyIcon />
              </ToggleButton>
            </ToggleButtonGroup> */}
        </Box>
      </ItineraryHeaderStyle>
      <ItineraryScrollContainer itinBarHeightPx={itinBarHeightPx}>
        <ItineraryList />
      </ItineraryScrollContainer>
    </>
  );
}

const ItineraryScrollContainer = styled.div`
  margin-top: ${(props) => props.itinBarHeightPx};
  height: calc(100% - ${(props) => props.itinBarHeightPx});
  width: 100%;
  position: absolute;
  overflow-x: auto;
  overflow-y: auto;
`;

const ItineraryHeaderStyle = styled.div`
  width: 100%;
  z-index: 1;
  box-shadow: 0px 1px 8px lightgrey;
  background-color: white;
  position: absolute;
`;
