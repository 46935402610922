import React from "react";
import styled from "styled-components";
import { Skeleton, Typography, Box } from "@mui/material";
import { getPrevGeo } from "../../../util/getItem";
import { useState, useEffect } from "react";

export default function LastItem({ dayIndex, itinerary, isDragging }) {
  const [item, setItem] = useState();

  useEffect(() => {
    const prev = getPrevGeo(dayIndex, 0, itinerary.days);
    if (prev) {
      setItem(itinerary.days[prev.day].items[prev.index]);
    } else {
      setItem(null);
    }
  }, [itinerary.days, dayIndex]);

  if (dayIndex === 0) {
    return;
  } else if (itinerary.days[dayIndex].items.length === 0) {
    return;
  } else if (item) {
    return (
      <>
        <LastContainerDiv>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "start",
              height: "100%",
            }}
          >
            {!isDragging ? (
              <Typography variant="subtitle2">
                Departing from{" "}
                {`${item.geo.formatted
                  .replace(", United States of America", "")
                  .replace(item.geo.postcode, "")}`}
              </Typography>
            ) : (
              <Skeleton width={250} animation="wave" />
            )}
          </Box>
        </LastContainerDiv>
      </>
    );
  }
}

const LastContainerDiv = styled.div`
  padding: 8px;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 1px 1px darkgrey;
  height: 30px;
  margin-bottom: 10px;
`;
