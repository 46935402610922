import React from "react";
import {
  Switch,
  Popover,
  List,
  ListItem,
  ListItemIcon,
  ListSubheader,
  ListItemText,
  IconButton,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import {
  getItineraryStatus,
  getShowPointLabels,
  setShowPointLabelsSaveDb,
} from "../../redux/itinerary";
import { setColorCodedDaysSaveDb } from "../../redux/itinerary";
import { getColorCodedDaysPref } from "../../redux/itinerary";
import PaletteIcon from "@mui/icons-material/Palette";
import SettingsIcon from "@mui/icons-material/Settings";
import LabelIcon from "@mui/icons-material/Label";

export default function ItinerarySettings() {
  const dispatch = useDispatch();
  const itineraryStatus = useSelector(getItineraryStatus);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const colorCodedDays = useSelector(getColorCodedDaysPref);
  const handleClickColorCodedDays = () => {
    dispatch(setColorCodedDaysSaveDb({ colorCodedDays: !colorCodedDays }));
  };

  const showPointLabels = useSelector(getShowPointLabels);
  const handleClickShowPointLabels = () => {
    dispatch(setShowPointLabelsSaveDb({ showPointLabels: !showPointLabels }));
  };
  return (
    <>
      <IconButton sx={{ flexGrow: 0, ml: 1 }} onClick={handleClick}>
        <SettingsIcon></SettingsIcon>
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <List
          sx={{
            width: "100%",
            maxWidth: 360,
            bgcolor: "background.paper",
          }}
          subheader={<ListSubheader>Settings</ListSubheader>}
        >
          <ListItem>
            <ListItemIcon>
              <LabelIcon />
            </ListItemIcon>
            <ListItemText
              id="switch-list-label-palette"
              primary="Map Stop Labels"
            />

            <Switch
              edge="end"
              checked={showPointLabels}
              onChange={handleClickShowPointLabels}
              disabled={itineraryStatus === "loading"}
            ></Switch>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <PaletteIcon />
            </ListItemIcon>
            <ListItemText
              id="switch-list-label-palette"
              primary="Color Code Days"
            />

            <Switch
              edge="end"
              checked={colorCodedDays}
              onChange={handleClickColorCodedDays}
              disabled={itineraryStatus === "loading"}
            ></Switch>
          </ListItem>
        </List>
      </Popover>
    </>
  );
}
