import React from "react";
import ChangePassword from "./ChangePassword";
import ChangeEmail from "./ChangeEmail";
import { useEffect } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

import Avatar from "@mui/material/Avatar";
import NavBar from "../NavBar";
import CssBaseline from "@mui/material/CssBaseline";
import ChangeAttributes from "./ChangeAttributes";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useAuth } from "./AuthContext";
import WarningIcon from "@mui/icons-material/Warning";
import VerifyEmail from "./VerifyEmail";

export default function ManageAccount() {
  const { getSession } = useAuth();

  const [userDetails, setUserDetails] = React.useState();

  useEffect(() => {
    getUserDetails();
  }, []);

  const getUserDetails = async () => {
    getSession().then((user) => {
      setUserDetails({
        email: user.email,
        emailVerified: user.email_verified === "true" ? true : false,
        user: user.user.username,
      });
    });
  };

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <NavBar></NavBar>
      {userDetails && (
        <Container component="main" maxWidth="sm">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 5,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography sx={{ mb: 2 }} component="h1" variant="h5">
              Account
            </Typography>
            <Avatar sx={{ height: 100, width: 100 }} alt="Tsfdssdf" src="" />
            <Typography sx={{ mt: 1, mb: 3 }} variant="h6">
              {userDetails.user}
            </Typography>

            <div>
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography sx={{ width: "33%", flexShrink: 0 }}>
                    Settings
                  </Typography>
                  <Typography sx={{ color: "text.secondary" }}>
                    Country, timezone, and user preferences
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <ChangeAttributes />
                </AccordionDetails>
              </Accordion>
              {!userDetails.emailVerified && (
                <Accordion
                  expanded={expanded === "panel4"}
                  onChange={handleChange("panel4")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panelconfirm-content"
                    id="panelconfirm-header"
                  >
                    <Typography sx={{ width: "33%", flexShrink: 0 }}>
                      Confirm Email
                    </Typography>
                    <WarningIcon sx={{ mr: 1 }} color="error"></WarningIcon>
                    <Typography color="error">Email Not Verified</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <VerifyEmail getUserDetails={getUserDetails} />
                  </AccordionDetails>
                </Accordion>
              )}
              <Accordion
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2bh-content"
                  id="panel2bh-header"
                >
                  <Typography sx={{ width: "33%", flexShrink: 0 }}>
                    Change Email
                  </Typography>
                  <Typography noWrap sx={{ color: "text.secondary" }}>
                    {userDetails.email}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <ChangeEmail
                    setExpanded={setExpanded}
                    getUserDetails={getUserDetails}
                  ></ChangeEmail>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === "panel3"}
                onChange={handleChange("panel3")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3bh-content"
                  id="panel3bh-header"
                >
                  <Typography sx={{ width: "33%", flexShrink: 0 }}>
                    Change Password
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <ChangePassword></ChangePassword>
                </AccordionDetails>
              </Accordion>
            </div>
          </Box>
        </Container>
      )}
    </>
  );
}
