import React, { createContext } from "react";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import Pool from "./userPoolConfig";
import { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const AuthContext = createContext();

const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider = (props) => {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);

  const location = useLocation();

  // try to get the user session to set user details on app load / route change
  useEffect(() => {
    setLoading(true);

    getSession()
      .then((user) => {
        setCurrentUser({
          username: user.user.username,
          timezone: user["custom:timezone"],
          distanceUnits: user["custom:distanceUnits"],
        });
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [location]);

  const getSession = async () =>
    await new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser();
      if (user) {
        user.getSession(async (err, session) => {
          if (err) {
            reject(err);
          } else {
            const attributes = await new Promise((resolve, reject) => {
              user.getUserAttributes((err, attributes) => {
                if (err) {
                  reject(err);
                } else {
                  const results = {};

                  for (let attribute of attributes) {
                    const { Name, Value } = attribute;
                    results[Name] = Value;
                  }
                  resolve(results);
                }
              });
            });

            resolve({
              user,
              ...session,
              ...attributes,
            });
          }
        });
      } else {
        reject();
      }
    });

  // const validateSession = async () =>
  //   await new Promise((resolve, reject) => {
  //     const user = Pool.getCurrentUser();
  //     if (user) {
  //       console.log("user");
  //       console.log(user);
  //       user.getSession(async (err, session) => {
  //         if (err) {
  //           reject(err);
  //         } else {
  //           resolve(session);
  //         }
  //       });
  //     } else {
  //       reject();
  //     }
  //   });

  const getToken = async () =>
    await new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser();
      if (user) {
        user.getSession(async (err, session) => {
          if (err) {
            reject(err);
          } else {
            const tokenHeaders = {
              headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${session.idToken.jwtToken}`,
              },
            };
            resolve(tokenHeaders);
          }
        });
      } else {
        reject();
      }
    });

  const authenticate = async (username, password) =>
    await new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username: username, Pool });
      const authDetails = new AuthenticationDetails({
        Username: username,
        Password: password,
      });

      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          resolve(data);
        },

        onFailure: (err) => {
          reject(err);
        },

        newpasswordRequired: (data) => {
          resolve(data);
        },
      });
    });

  const login = async (username, password) =>
    await new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username: username, Pool });
      const authDetails = new AuthenticationDetails({
        Username: username,
        Password: password,
      });

      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          setCurrentUser(username);
          resolve(data);
        },

        onFailure: (err) => {
          reject(err);
        },

        newPasswordRequired: (data) => {
          resolve(data);
        },
      });
    });

  const logout = () => {
    const user = Pool.getCurrentUser();
    if (user) {
      user.signOut();
      setCurrentUser(null);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        authenticate,
        getSession,
        logout,
        login,
        getToken,
      }}
    >
      {!loading && props.children}
    </AuthContext.Provider>
  );
};
export { useAuth, AuthProvider };
