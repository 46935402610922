import React, { useState } from "react";

import {
  Grid,
  TextField,
  InputAdornment,
  IconButton,
  Typography,
  List,
  Button,
  ListItemText,
  Collapse,
  Divider,
  CircularProgress,
  Box,
  ToggleButtonGroup,
  ToggleButton,
  ListItemButton,
} from "@mui/material";
import axios from "axios";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import FlightIcon from "@mui/icons-material/Flight";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch } from "react-redux";
import {
  getGeocodeDataForItem,
  deleteItemAsync,
  getItineraryStatus,
  getItineraryRouteStatus,
} from "../../../../redux/itinerary";
import { createAlert } from "../../../../redux/alert";
import { useSelector } from "react-redux";

export default function Address({ item, index, dayIndex }) {
  const itineraryStatus = useSelector(getItineraryStatus);
  const routeStatus = useSelector(getItineraryRouteStatus);
  const dispatch = useDispatch();
  const handleClickSearch = () => {
    getAddresses(addressField);
  };

  const handleSearch = (e) => {
    //it triggers by pressing the enter key
    if (e.key === "Enter") {
      getAddresses(addressField);
    }
  };
  const getAddresses = async (a) => {
    const address = a.trim();

    if (address === lastAddressSearched) {
      return;
    } else if (address.length < 3) {
      if (address.length < 1) {
        setSearched(false);
      } else {
        setSearched(true);
      }
      setAddressResults([]);
      return;
    }
    setLoading(true);
    try {
      const response = await axios.get(
        `https://api.geoapify.com/v1/geocode/autocomplete?text=${address}&format=json&apiKey=fa0b0783bd924f7cac4b185fe19f74ff`
      );
      setAddressResults(response.data.results);
      setLastAddressSearched(address);
      setLoading(false);
      setSearched(true);
    } catch (e) {
      dispatch(createAlert({ message: "Address Search Error", type: "error" }));
      setAddressResults([]);
      setLastAddressSearched("");
      setLoading(false);
      setSearched(true);
    }
  };

  const handleClear = () => {
    setAddressResults([]);
    setAddressField("");
    setLastAddressSearched("");
    setSearched(false);
  };
  const [addressResults, setAddressResults] = useState([]);
  const [addressField, setAddressField] = useState("");
  const [lastAddressSearched, setLastAddressSearched] = useState("");
  const [loading, setLoading] = useState(false);
  const [searched, setSearched] = useState(false);

  function handleAddLocation(geo) {
    const isFlight = travelMode === "flight" ? true : false;
    dispatch(
      getGeocodeDataForItem({
        geo,
        itemId: item.itemId,
        day: dayIndex,
        index: index,
        isFlight,
      })
    );
  }

  const [travelMode, setTravelMode] = useState("drive");

  const handleSetTravelMode = (e, value) => {
    if (value !== null) {
      setTravelMode(value);
    }
  };

  function handleDeleteItem() {
    dispatch(deleteItemAsync({ itemId: item.itemId, day: dayIndex, index }));
  }

  return (
    <>
      <Grid
        item
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{ pl: 1 }}
      >
        <Grid item sx={{ width: "100%", display: "flex" }}>
          <ToggleButtonGroup
            size="small"
            value={travelMode}
            exclusive
            onChange={handleSetTravelMode}
            aria-label="travel mode"
            sx={{ flexGrow: 0, marginRight: 1 }}
          >
            <ToggleButton value="drive" aria-label="left aligned">
              <DriveEtaIcon />
            </ToggleButton>

            <ToggleButton value="flight" aria-label="centered">
              <FlightIcon />
            </ToggleButton>
          </ToggleButtonGroup>

          <TextField
            sx={{ flexGrow: 1 }}
            label="Place / Address"
            onChange={(e) => setAddressField(e.target.value)}
            onKeyPress={handleSearch}
            value={addressField}
            autoFocus={true}
            autoComplete="off"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {addressField.length > 0 || addressResults.length > 0 ? (
                    <IconButton onClick={handleClear}>
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  ) : (
                    <></>
                  )}
                  {!loading ? (
                    <IconButton onClick={handleClickSearch}>
                      <SearchIcon fontSize="small" />
                    </IconButton>
                  ) : (
                    <CircularProgress
                      color="inherit"
                      sx={{ margin: 1 }}
                      size={20}
                    />
                  )}
                </InputAdornment>
              ),
            }}
            size="small"
          ></TextField>
          <IconButton
            disabled={
              routeStatus === "loading" || itineraryStatus === "loading"
            }
            aria-label="delete"
            onClick={handleDeleteItem}
            sx={{ flexGrow: 0, marginLeft: 1, height: 40 }}
          >
            <DeleteIcon />
          </IconButton>
        </Grid>
        <Grid item sx={{ width: "100%", marginTop: 1 }}>
          {searched ? (
            addressResults.length < 1 ? (
              <Box sx={{ textAlign: "center" }}>
                <Typography variant="body2">No Results</Typography>
              </Box>
            ) : (
              <></>
            )
          ) : (
            <Box sx={{ textAlign: "center" }}>
              <Button fullWidth onClick={handleClickSearch}>
                Search
              </Button>
            </Box>
          )}
        </Grid>
        <Grid item sx={{ width: "100%" }}>
          <Collapse in={addressResults.length > 0}>
            <List dense={true} disablePadding={true}>
              {addressResults.length > 0 ? (
                addressResults.map((result, resultIndex) => {
                  return (
                    <div key={resultIndex}>
                      <Divider></Divider>
                      <ListItemButton
                        dense={true}
                        disableGutters={true}
                        onClick={() => handleAddLocation(result)}
                        sx={{ p: 0 }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                            mx: 1,
                          }}
                        >
                          <ListItemText
                            primary={result.address_line1}
                            secondary={result.address_line2}
                            sx={{ maxWidth: "85%" }}
                          />
                          <AddLocationAltIcon color="primary" />
                        </Box>
                      </ListItemButton>
                    </div>
                  );
                })
              ) : (
                <></>
              )}
              <Divider></Divider>
            </List>
          </Collapse>
        </Grid>
      </Grid>
    </>
  );
}
