import React from "react";

import { useSelector, useDispatch } from "react-redux";
import { getItinerary } from "../../redux/itinerary";
import midString from "../../util/order";
import { updateItemOrderAsync } from "../../redux/itinerary";

import ListView from "./list-view/ListView";

export default function ItineraryList() {
  const itinerary = useSelector(getItinerary);
  const dispatch = useDispatch();
  const [isDragging, setIsDragging] = React.useState(false);

  const onDragStart = (start) => {
    setIsDragging(true);
  };

  function onDragEnd(result) {
    setIsDragging(false);
    const { destination, source, draggableId } = result;

    if (
      !destination ||
      (destination.index === source.index &&
        destination.droppableId === source.droppableId)
    ) {
      return;
    }

    const sourceDay = +source.droppableId;
    const sourceIdx = +source.index;
    const destDay = +destination.droppableId;
    const destIdx = +destination.index;

    const destDayItemsLength = itinerary.days[destDay].items.length;
    let prev = "";
    let next = "";
    // if day array is empty then we will end up passing empty strings to the midString algorithm
    if (destDayItemsLength !== 0) {
      // if we are dragging something down the list everything above the destination index up to the original
      // source index will shift up, we need to account for this when selecting prev and next
      if (destDay === sourceDay) {
        if (destIdx > sourceIdx) {
          prev = itinerary.days[destDay].items[destIdx].order;
          // if destIdx + 1 is out of bounds then we know we are setting the item to the last item and 'next' should be empty
          if (destDayItemsLength > destIdx + 1) {
            next = itinerary.days[destDay].items[destIdx + 1].order;
          }
        } else {
          // now we can check cases where dragging the item up and the item below the destination index shifts down
          if (destIdx !== 0) {
            // if we are moving the item to the top of the list
            prev = itinerary.days[destDay].items[destIdx - 1].order;
          }

          // if
          next = itinerary.days[destDay].items[destIdx].order;
        }
      } else {
        if (destIdx !== 0) {
          prev = itinerary.days[destDay].items[destIdx - 1].order;
        }

        if (destIdx < destDayItemsLength) {
          next = itinerary.days[destDay].items[destIdx].order;
        }
      }
    }

    dispatch(
      updateItemOrderAsync({
        itemId: draggableId,
        newOrder: midString(prev, next),
        oldOrder: itinerary.days[sourceDay].items[sourceIdx].order,
        destDay: destDay,
        sourceDay: sourceDay,
        destIdx: destIdx,
        sourceIdx: sourceIdx,
      })
    );
  }

  return (
    <ListView
      onDragEnd={onDragEnd}
      onDragStart={onDragStart}
      isDragging={isDragging}
      itinerary={itinerary}
    />
  );
}
