import React from "react";
import {
  Grid,
  Box,
  CardActionArea,
  Container,
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
  Divider,
  Skeleton,
} from "@mui/material";
import axios from "axios";
import { useState, useEffect } from "react";
import NavBar from "./NavBar";
import { useAuth } from "./auth/AuthContext";

import EditItineraryModal from "./EditItineraryModal";
import CreateItineraryModal from "./CreateItineraryModal";
import dayjs from "dayjs";
import CircularProgress from "@mui/material/CircularProgress";
import { ConfirmProvider } from "material-ui-confirm";
import { useConfirm } from "material-ui-confirm";

export default function Itineraries() {
  const { getToken } = useAuth();
  const [itineraries, setItineraries] = useState([]);
  const [loadingItineraries, setLoadingItineraries] = useState(true);
  const { currentUser } = useAuth();
  const confirm = useConfirm();

  const utc = require("dayjs/plugin/utc");
  const timezone = require("dayjs/plugin/timezone"); // dependent on utc plugin
  dayjs.extend(utc);
  dayjs.extend(timezone);

  useEffect(() => {
    setLoadingItineraries(true);
    getItineraries();
  }, []);

  const getItineraries = async () => {
    try {
      const config = await getToken();
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_API}/itineraries`,
        config
      );
      if (response.data?.Items) {
        const items = response.data.Items;
        //console.log(items);
        items.sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt));
        //console.log(items);

        setItineraries(items);
      } else {
        setItineraries([]);
      }

      setLoadingItineraries(false);
    } catch (e) {
      setItineraries([]);
      setLoadingItineraries(false);
    }
  };

  const handleDeleteItinerary = async (itinerary) => {
    confirm({
      description: `Your itinerary (${itinerary.title}) will be permanently deleted.`,
      confirmationText: "Delete",
      confirmationButtonProps: {
        color: "error",
      },
    }).then(async () => {
      try {
        const config = await getToken();
        const response = await axios.delete(
          `${process.env.REACT_APP_BACKEND_API}/itinerary/delete/${itinerary.itineraryId}`,
          config
        );
        setLoadingItineraries(true);
        getItineraries();
      } catch (e) {}
    });
  };

  return (
    <>
      <NavBar />
      <Container component="main">
        <Box
          sx={{
            marginTop: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h5">
            Your Itineraries
          </Typography>
          <Divider sx={{ width: { xs: "100%", md: "100%" }, mt: 3 }}></Divider>
          <CreateItineraryModal
            getItineraries={getItineraries}
          ></CreateItineraryModal>

          <Divider sx={{ width: { xs: "100%", md: "100%" }, mt: 2 }}></Divider>
          <Grid
            item
            container
            spacing={2}
            justifyContent="center"
            sx={{
              mt: 3,
              mb: 5,
            }}
          >
            {loadingItineraries ? (
              <CircularProgress sx={{ mt: 8 }} color="primary" />
            ) : itineraries.length > 0 ? (
              itineraries.map((itinerary, idx) => (
                <Grid
                  item
                  key={itinerary.itineraryId}
                  xs={12}
                  sm={6}
                  md={4}
                  lg={4}
                  xl={3}
                >
                  <Card
                    sx={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <CardActionArea
                      href={`/edit/${itinerary.itineraryId}`}
                      sx={{ height: "100%" }}
                    >
                      <CardContent>
                        <Typography variant="h5" underline="hover">
                          {loadingItineraries ? <Skeleton /> : itinerary.title}
                        </Typography>
                        <Typography variant="caption" underline="hover">
                          {loadingItineraries ? (
                            <Skeleton />
                          ) : (
                            dayjs(itinerary.createdAt)
                              .tz(currentUser.timezone)
                              .format("[Created on] MMMM D, YYYY [at] h:mm A")
                          )}
                        </Typography>
                      </CardContent>
                      {/* <CardMedia
                        component="img"
                        height="150"
                        image="https://source.unsplash.com/random"
                        alt="random"
                        href={`/edit/${itinerary.itineraryId}`}
                      /> */}
                      {itinerary.description && (
                        <CardContent sx={{ flexGrow: 1, height: "100%" }}>
                          <Typography>{itinerary.description}</Typography>
                        </CardContent>
                      )}
                    </CardActionArea>
                    <CardActions sx={{ flexGrow: 0 }}>
                      <Button
                        size="small"
                        href={`/edit/${itinerary.itineraryId}`}
                      >
                        Open
                      </Button>
                      <EditItineraryModal
                        itinerary={itinerary}
                        getItineraries={getItineraries}
                      ></EditItineraryModal>
                      <Button
                        size="small"
                        onClick={() => handleDeleteItinerary(itinerary)}
                      >
                        Delete
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              ))
            ) : (
              <Grid item>
                <Typography
                  color="text.secondary"
                  variant="subtitle2"
                  sx={{ mt: 4, textAlign: "center" }}
                >
                  You don't have any itineraries
                </Typography>
              </Grid>
            )}
          </Grid>
        </Box>
      </Container>
    </>
  );
}
