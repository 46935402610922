import React from "react";
import Map, { AttributionControl } from "react-map-gl";
import maplibregl from "maplibre-gl";
import bbox from "@turf/bbox";
import { lineString } from "@turf/helpers";
import "maplibre-gl/dist/maplibre-gl.css";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getColorCodedDaysPref,
  getShowPointLabels,
  getItinerary,
  getItineraryRouteStatus,
  getItineraryStatus,
  getSelectedDay,
  getSelectedRouteItem,
  setSelectedDay,
  setSelectedRouteItem,
  getPopup,
  setPopup,
} from "../../redux/itinerary";
import "./VectorMap.css";
import {
  Source,
  Layer,
  ScaleControl,
  NavigationControl,
  FullscreenControl,
  Marker,
  Popup,
} from "react-map-gl";

import CustomMarkerPopup from "./CustomMarkerPopup";
import {
  clusterLayer,
  clusterCountLayer,
  pointLayer,
  pointLabelLayer,
  pointLetterLayer,
} from "./layers";

import { getPrevGeo } from "../../util/getItem";
import SelectedDayOverlay from "./SelectedDayOverlay";
import { useWindowSize } from "@react-hook/window-size/throttled";
import location from "../../assets/rbc.svg";
import { Typography } from "@mui/material";

const mapSchema =
  "https://api.maptiler.com/maps/basic-v2/style.json?key=HO3gJc8nopSbDHVb3N4z";

export default function VectorMap() {
  const [width, height] = useWindowSize();
  const itinerary = useSelector(getItinerary);
  const dispatch = useDispatch();
  const mapRef = React.useRef();
  const routeStatus = useSelector(getItineraryRouteStatus);
  const itineraryStatus = useSelector(getItineraryStatus);
  const selectedRouteItem = useSelector(getSelectedRouteItem);
  const selectedDay = useSelector(getSelectedDay);
  const colorCodedDays = useSelector(getColorCodedDaysPref);
  const showPointlabels = useSelector(getShowPointLabels);

  const popup = useSelector(getPopup);

  const [routeCollection, setRouteCollection] = useState({
    type: "FeatureCollection",
    features: [],
  });
  const [pointCollection, setPointCollection] = useState({
    type: "FeatureCollection",
    features: [],
  });

  // inital view state
  const [initialViewState, setInitialViewState] = useState();
  useEffect(() => {
    const coords = [];
    for (let i = 0; i < itinerary.days.length; i++) {
      for (let j = 0; j < itinerary.days[i].items.length; j++) {
        const item = itinerary.days[i].items[j];

        if (item.geo) {
          coords.push([item.geo.lon, item.geo.lat]);
        }
      }
    }

    if (coords.length > 1) {
      const newline = lineString(coords);
      const [minLng, minLat, maxLng, maxLat] = bbox(newline);
      setInitialViewState({
        bounds: [
          [minLng, minLat],
          [maxLng, maxLat],
        ],
        fitBoundsOptions: { padding: 100 },
      });
    } else if (coords.length === 1) {
      setInitialViewState({
        longitude: coords[0][0],
        latitude: coords[0][1],
        zoom: 5,
      });
    } else {
      setInitialViewState({
        longitude: -100,
        latitude: 40,
        zoom: 1,
      });
    }
  }, []);

  // updating the displayed route
  useEffect(() => {
    if (routeStatus === "succeeded") {
      if (selectedDay !== null) {
        dispatch(setSelectedDay({ day: null }));
      }
      setRoute();
    }
  }, [routeStatus]);

  useEffect(() => {
    if (selectedDay !== null) {
      setSelectedDayRoute();
    } else {
      setRoute();
    }
  }, [selectedDay]);

  const setRoute = () => {
    const routeFeatures = [];
    const pointFeatures = [];
    for (let i = 0; i < itinerary.days.length; i++) {
      for (let j = 0; j < itinerary.days[i].items.length; j++) {
        const item = itinerary.days[i].items[j];
        if (item.includeInRouting && item.route?.geojson) {
          routeFeatures.push({
            type: "Feature",
            geometry: item.route.geojson,
            properties: {
              distance: item.route.distance,
              time: item.route.time,
              dayIndex: i,
              itemIndex: j,
              itemId: item.itemId,
              color: itinerary.days[i].color,
              opacity: !itinerary.selectedRouteItem
                ? 1
                : item.itemId === itinerary.selectedRouteItem.itemId
                ? 1
                : 0.4,
            },
          });
        }
        if (item.geo && item.includeInMap) {
          pointFeatures.push({
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [item.geo.lon, item.geo.lat],
            },
            properties: {
              name: item.geo.name,
              city: item.geo.city,
              state: item.geo.state,
              state_code: item.geo.state_code,
              country: item.geo.country,
              address: item.geo.address_line1,
              dayIndex: i,
              itemIndex: j,
              itemLetter: String.fromCharCode(j + 65),
              color: itinerary.days[i].color,
              opacity: !itinerary.selectedRouteItem ? 1 : 0.7,
            },
          });
        }
      }
    }
    setRouteCollection({
      type: "FeatureCollection",
      features: routeFeatures,
    });

    setPointCollection({
      type: "FeatureCollection",
      features: pointFeatures,
    });
  };

  const setSelectedDayRoute = () => {
    const routeFeatures = [];
    const pointFeatures = [];
    const points = [];
    const prev = getPrevGeo(selectedDay, 0, itinerary.days);
    if (prev) {
      const item = itinerary.days[prev.day].items[prev.index];
      pointFeatures.push({
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [item.geo.lon, item.geo.lat],
        },
        properties: {
          name: item.geo.name,
          city: item.geo.city,
          state: item.geo.state,
          state_code: item.geo.state_code,
          country: item.geo.country,
          address: item.geo.address_line1,
          itemIndex: prev.index,
          dayIndex: prev.day,
          opacity: !itinerary.selectedRouteItem ? 1 : 0.7,
          color: itinerary.days[prev.day].color,
        },
      });
      points.push([item.geo.lon, item.geo.lat]);
    }
    for (let i = 0; i < itinerary.days[selectedDay].items.length; i++) {
      const item = itinerary.days[selectedDay].items[i];
      if (item.includeInRouting && item.route?.geojson) {
        routeFeatures.push({
          type: "Feature",
          geometry: item.route.geojson,
          properties: {
            distance: item.route.distance,
            time: item.route.time,
            dayIndex: selectedDay,
            itemIndex: i,
            itemId: item.itemId,
            color: itinerary.days[selectedDay].color,
            opacity: !itinerary.selectedRouteItem
              ? 1
              : item.itemId === itinerary.selectedRouteItem.itemId
              ? 1
              : 0.4,
          },
        });
      }
      if (item.geo && item.includeInMap) {
        pointFeatures.push({
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [item.geo.lon, item.geo.lat],
          },
          properties: {
            name: item.geo.name,
            city: item.geo.city,
            state: item.geo.state,
            state_code: item.geo.state_code,
            country: item.geo.country,
            address: item.geo.address_line1,
            dayIndex: selectedDay,
            itemIndex: i,
            itemLetter: String.fromCharCode(i + 65),
            opacity: !itinerary.selectedRouteItem ? 1 : 0.7,
            color: itinerary.days[selectedDay].color,
          },
        });
        points.push([item.geo.lon, item.geo.lat]);
      }
    }

    setRouteCollection({
      type: "FeatureCollection",
      features: routeFeatures,
    });

    setPointCollection({
      type: "FeatureCollection",
      features: pointFeatures,
    });

    setBounds(points);
  };

  const setBounds = (coords) => {
    if (coords.length === 0 || !coords) {
      return;
    } else if (coords.length === 1) {
      mapRef.current.flyTo({
        center: coords[0],
        zoom: 5,
        speed: 1,
      });
    } else {
      const newline = lineString(coords);
      const [minLng, minLat, maxLng, maxLat] = bbox(newline);
      mapRef.current.fitBounds(
        [
          [minLng, minLat],
          [maxLng, maxLat],
        ],
        { padding: { top: 60, left: 60, right: 60, bottom: 200 } }
      );
    }
  };

  // route layer options https://maplibre.org/maplibre-gl-js-docs/style-spec/layers/
  const routeLayer = {
    id: "route",
    type: "line",
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": colorCodedDays ? ["get", "color"] : "#000000",
      "line-width": 4.5,
      "line-blur": 0.5,
      "line-opacity": 1,
    },
  };

  const [interactiveLayers, setInteractiveLayers] = useState([]);
  const onLoad = () => {
    setInteractiveLayers([
      pointLayer.id,
      routeLayer.id,
      clusterLayer.id,
      clusterCountLayer.id,
    ]);
  };

  // on map click
  const onClick = (event) => {
    //console.log(mapRef.current.getStyle().layers);
    const features = event.features;
    event.originalEvent.stopPropagation();

    if (features.length === 0) {
      dispatch(
        setSelectedRouteItem({
          item: null,
        })
      );
      dispatch(setSelectedDay({ day: null }));
    }

    let point = null;
    let cluster = null;

    for (let i = 0; i < features.length; i++) {
      if (features[i].layer.id === "point") {
        point = i;
      } else if (features[i].layer.id === "clusters") {
        cluster = i;
      }
    }

    if (point !== null) {
      const { dayIndex, itemIndex } = features[point].properties;
      const item = itinerary.days[dayIndex].items[itemIndex];

      dispatch(setPopup({ item }));
    } else if (cluster !== null) {
      const clusterId = features[cluster].properties.cluster_id;

      const mapboxSource = mapRef.current.getSource("points");

      mapboxSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
        if (err) {
          return;
        }

        mapRef.current.easeTo({
          center: features[cluster].geometry.coordinates,
          zoom,
          duration: 500,
        });
      });
    } else if (features.length > 0 && features[0].layer.id === "route") {
      const { dayIndex, itemIndex } = features[0].properties;
      if (selectedDay !== null) {
        dispatch(
          setSelectedRouteItem({
            item: itinerary.days[dayIndex].items[itemIndex],
          })
        );
      } else {
        dispatch(
          setSelectedDay({
            day: dayIndex,
          })
        );
      }
    }
  };

  // change cursor to click
  const [cursor, setCursor] = useState("auto");
  const onMouseEnter = React.useCallback((event) => {
    setCursor("pointer");
  }, []);
  const onMouseLeave = React.useCallback(() => setCursor("auto"), []);

  if (initialViewState) {
    return (
      <Map
        id="vectorMap"
        style={
          width > height
            ? width >= 900
              ? { width: "100%", height: "calc(100vh - 64px)" }
              : { width: "100%", height: "100vh" }
            : { width: "100vw", height: "100%" }
        }
        initialViewState={initialViewState}
        mapLib={maplibregl}
        mapStyle={mapSchema}
        onLoad={onLoad}
        cursor={cursor}
        onClick={onClick}
        interactiveLayerIds={interactiveLayers}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        ref={mapRef}
        attributionControl={false}
        localFontFamily={["Inter"]}
        localIdeographFontFamily={["Inter"]}
        clickTolerance={10}
      >
        <Source key="route" type="geojson" data={routeCollection}>
          <Layer {...routeLayer} />
        </Source>
        <Source
          id="points"
          key="points"
          type="geojson"
          data={pointCollection}
          cluster={true}
          clusterMaxZoom={14}
          clusterRadius={7}
        >
          <Layer {...clusterLayer} />
          <Layer {...clusterCountLayer} />
          <Layer {...pointLayer} />
        </Source>

        {showPointlabels && (
          <Source
            data={pointCollection}
            id="point-labels"
            key="pointLabels"
            type="geojson"
            cluster={true}
            clusterMaxZoom={14}
            clusterRadius={7}
          >
            <Layer {...pointLabelLayer} />
          </Source>
        )}

        {popup && <CustomMarkerPopup />}
        {selectedDay !== null && (
          <SelectedDayOverlay day={itinerary.days[selectedDay]} />
        )}
        <NavigationControl />
        <FullscreenControl />
        <AttributionControl position="bottom-left" />
        {/* <ScaleControl position="bottom-left" unit="imperial" maxWidth="150" /> */}
      </Map>
    );
  }
}
