import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";

import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";

import { Container } from "@mui/material";

import { useAuth } from "./AuthContext";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { createAlert } from "../redux/alert";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useState } from "react";
import { CognitoUser } from "amazon-cognito-identity-js";
import Pool from "./userPoolConfig";
import Logo from "../Logo";

export default function ConfirmAccount() {
  const { currentUser, login } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formValidationSchema = Yup.object().shape({
    username: Yup.string()
      .required("Username is required")
      .min(3, "Username must contain at least 3 characters")
      .max(25, "Username can not contain more than 25 characters")
      .matches(/^[a-zA-Z0-9_-]+$/, "Invalid username"),
    code: Yup.string()
      .required("Email confirmation code required")
      .matches(/^[0-9]{6,6}$/, "Invalid confirmation code"),
  });

  const {
    register,
    reset,
    handleSubmit,
    setValue,
    formState: { errors, dirtyFields, isDirty, isValid },
  } = useForm({
    resolver: yupResolver(formValidationSchema, {
      validateAllFieldCriteria: true,
    }),
    criteriaMode: "all",
    mode: "onChange",
  });

  const { state } = useLocation();

  if (state) {
    setValue("username", state.username);
  }

  const [loading, setLoading] = useState(false);

  const handleConfirm = (formData) => {
    setLoading(true);
    const { username } = formData;

    const user = new CognitoUser({
      Username: username,
      Pool,
    });

    user.confirmRegistration(formData.code, false, (e, result) => {
      if (e) {
        dispatch(createAlert({ message: e.message, type: "error" }));
        reset(formData);
      } else {
        navigate("../login", { replace: true });
        dispatch(
          createAlert({
            message: "Account activation complete",
            type: "success",
          })
        );
      }
    });
  };

  const handleResendCode = () => {
    if (!state) {
      return;
    }
    const { username } = state;

    const user = new CognitoUser({
      Username: username,
      Pool,
    });

    user.resendConfirmationCode((e, result) => {
      if (e) {
        dispatch(createAlert({ message: e.message, type: "error" }));
      } else {
        dispatch(
          createAlert({
            message: "New confirmation code sent",
            type: "info",
          })
        );
      }
    });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 6,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Logo></Logo>
        <Avatar sx={{ mt: 5, mb: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Confirm Account
        </Typography>
        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit(handleConfirm)}
          sx={{ mt: 3 }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {!state ? (
                <>
                  <Typography
                    component="h1"
                    variant="body2"
                    sx={{ textAlign: "center", mb: "10px" }}
                  >
                    Please enter your username and the confirmation code sent to
                    the email associated with your account
                  </Typography>
                  <TextField
                    required
                    fullWidth
                    id="username"
                    label="Username"
                    name="username"
                    autoComplete="username"
                    {...register("username")}
                    error={!!errors?.username}
                    helperText={errors.username ? errors.username.message : " "}
                  />
                </>
              ) : (
                <Typography
                  {...register("username")}
                  component="h1"
                  variant="body2"
                  sx={{ textAlign: "center" }}
                >
                  Please enter the confirmation code sent to the email
                  associated with your account{" "}
                  {state.email ? <strong>{state.email}</strong> : ""}
                </Typography>
              )}
            </Grid>

            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="code"
                label="Code"
                id="code"
                autoComplete="current-password"
                {...register("code")}
                error={!!errors?.code}
                helperText={errors.code ? errors.code.message : " "}
                type="text"
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={!isDirty || !isValid}
            sx={{ mt: 3, mb: 2 }}
          >
            Next
          </Button>
        </Box>
        {state ? (
          <Link
            component="button"
            variant="body2"
            onClick={() => {
              handleResendCode();
            }}
          >
            Resend confirmation code
          </Link>
        ) : (
          <></>
        )}
      </Box>
    </Container>
  );
}
