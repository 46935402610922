import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteItemAsync,
  getItineraryRouteStatus,
  getItineraryStatus,
  setPopup,
} from "../../../redux/itinerary";
import DeleteIcon from "@mui/icons-material/Delete";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { Box, IconButton } from "@mui/material";
import Typography from "@mui/material/Typography";
import InfoExpand from "./InfoExpand";

export default function ItemInfoDetail({ item, index, dayIndex }) {
  const dispatch = useDispatch();

  function handleDeleteItem() {
    dispatch(deleteItemAsync({ itemId: item.itemId, day: dayIndex, index }));
  }

  const handleSetPopup = () => {
    dispatch(setPopup({ item }));
  };
  const handleSetPopupNull = () => {
    dispatch(setPopup({ item: null }));
  };

  const routeStatus = useSelector(getItineraryRouteStatus);
  const itineraryStatus = useSelector(getItineraryStatus);

  return (
    <Box sx={{ display: "flex" }}>
      <DragIndicatorIcon
        sx={{
          fontSize: "1.5em",
          alignSelf: "center",
          flexGrow: 0,
          mr: 1,
          pl: "10px",
        }}
        color={
          routeStatus === "loading" || itineraryStatus === "loading"
            ? "disabled"
            : "action"
        }
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mb: 1,
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              mr: "10px",
            }}
          >
            <Typography variant="h6" sx={{ mt: 1 }}>
              {item.geo.address_line1}
            </Typography>
            <Typography variant="subtitle2" color="text.secondary">
              {item.geo.address_line2}
            </Typography>
          </Box>

          <IconButton
            disabled={
              routeStatus === "loading" || itineraryStatus === "loading"
            }
            aria-label="delete"
            onClick={handleDeleteItem}
            sx={{
              fontSize: "1.5em",
              alignSelf: "center",
              flexGrow: 0,
              mr: 1.25,
              mt: 0.5,
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
        <InfoExpand item={item} day={dayIndex} index={index} />
      </Box>
    </Box>
  );
}
