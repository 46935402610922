import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

import { useState } from "react";

import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import YupPassword from "yup-password";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useDispatch } from "react-redux";
import { createAlert } from "../redux/alert";

import { CognitoUser } from "amazon-cognito-identity-js";
import Pool from "./userPoolConfig";
import Logo from "../Logo";

YupPassword(Yup); // extend yup

export default function ForgotPassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [stage, setStage] = useState(1); // 1 = username stage, 2 = code stage
  const [username, setUsername] = useState("");
  const [codeEmail, setCodeEmail] = useState("");

  // stage 1 find user by username
  const formValidationSchema = Yup.object().shape({
    username: Yup.string()
      .required("Username is required")
      .required("A username is required")
      .min(3, "Username must contain at least 3 characters")
      .max(25, "Username can not contain more than 25 characters")
      .matches(/^[a-zA-Z0-9_-]+$/, "Invalid username"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm({
    resolver: yupResolver(formValidationSchema, {
      validateAllFieldCriteria: true,
    }),
    criteriaMode: "all",
    mode: "onChange",
  });

  const getUser = (username) => {
    return new CognitoUser({
      Username: username,
      Pool,
    });
  };

  const handleSendCode = (getUsernameFormData) => {
    setUsername(getUsernameFormData.username);

    getUser(getUsernameFormData.username).forgotPassword({
      onSuccess: (data) => {},
      onFailure: (e) => {
        dispatch(createAlert({ message: e.message, type: "error" }));
      },
      inputVerificationCode: (data) => {
        setCodeEmail(data.CodeDeliveryDetails.Destination);
        setStage(2);
      },
    });
  };

  // stage 2 enter code and new password

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const handleClickShowPasswordConfirm = () =>
    setShowPasswordConfirm(!showPasswordConfirm);
  const handleMouseDownPasswordConfirm = () =>
    setShowPasswordConfirm(!showPasswordConfirm);

  const resetPasswordFormValidationSchema = Yup.object().shape({
    code: Yup.string()
      .required("Verification code required")
      .matches(/^[0-9]{6,6}$/, "Invalid verification code"),
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password length should be at least 8 characters")
      .minLowercase(1, "Password must contain at least 1 lower case letter")
      .minUppercase(1, "Password must contain at least 1 upper case letter")
      .minNumbers(1, "Password must contain at least 1 number")
      .minSymbols(1, "Password must contain at least 1 special character"),
    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf(
        [Yup.ref("password")],
        "Confirmation password does not match new password"
      ),
  });

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    formState: {
      errors: errors2,
      isDirty: isDirty2,
      isValid: isValid2,
      dirtyFields: dirtyFields2,
    },
  } = useForm({
    resolver: yupResolver(resetPasswordFormValidationSchema, {
      validateAllFieldCriteria: true,
    }),
    criteriaMode: "all",
    mode: "onChange",
  });

  const handleResetPassword = (resetPasswordFormData) => {
    getUser(username).confirmPassword(
      resetPasswordFormData.code,
      resetPasswordFormData.password,
      {
        onSuccess: (data) => {
          navigate("../login", { replace: true });
        },
        onFailure: (e) => {
          dispatch(createAlert({ message: e.message, type: "error" }));
        },
      }
    );
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Logo></Logo>
        <Avatar sx={{ mt: 5, mb: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Reset Password
        </Typography>
        {stage === 1 && (
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit(handleSendCode)}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography>Enter your username below</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="username"
                  label="Username"
                  name="username"
                  autoComplete="username"
                  {...register("username")}
                  error={!!errors?.username}
                  helperText={errors.username ? errors.username.message : " "}
                />
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    disabled={!isDirty || !isValid}
                    sx={{ mt: 1, mb: 2 }}
                  >
                    Next
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        )}
        {stage === 2 && (
          <Box
            component="form"
            onSubmit={handleSubmit2(handleResetPassword)}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography>
                  A verification code has been sent to {codeEmail}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="code"
                  label="Verification Code"
                  id="code"
                  autoComplete="off"
                  {...register2("code")}
                  error={!!errors2?.code}
                  helperText={errors2.code ? errors2.code.message : " "}
                  type="text"
                />
              </Grid>
              <Grid
                item
                xs={12}
                container
                direction="column"
                sx={{ marginLeft: "14px", marginRight: "14px" }}
              >
                <Grid item container justifyContent="start">
                  <Typography
                    variant="subtitle2"
                    sx={{ marginBottom: "5px", fontWeight: "1000" }}
                  >
                    New Password Requirements:
                  </Typography>
                </Grid>

                <Grid item container justifyContent="space-between">
                  <Typography
                    variant="subtitle2"
                    color={
                      !errors2?.password && !dirtyFields2?.password
                        ? "initial"
                        : !errors2?.password?.types.min &&
                          dirtyFields2?.password
                        ? "primary"
                        : "error"
                    }
                  >
                    Contain at least 8 characters
                  </Typography>
                  {!errors2?.password && !dirtyFields2?.password ? (
                    <CloseIcon fontSize="small" color="initial"></CloseIcon>
                  ) : !errors2?.password?.types.min &&
                    dirtyFields2?.password ? (
                    <CheckIcon fontSize="small" color="primary"></CheckIcon>
                  ) : (
                    <CloseIcon fontSize="small" color="error"></CloseIcon>
                  )}
                </Grid>
                <Grid item container justifyContent="space-between">
                  <Typography
                    variant="subtitle2"
                    color={
                      !errors2?.password && !dirtyFields2?.password
                        ? "initial"
                        : !errors2?.password?.types.minUppercase &&
                          dirtyFields2?.password
                        ? "primary"
                        : "error"
                    }
                  >
                    Contain an uppercase letter
                  </Typography>
                  {!errors2?.password && !dirtyFields2?.password ? (
                    <CloseIcon fontSize="small" color="initial"></CloseIcon>
                  ) : !errors2?.password?.types.minUppercase &&
                    dirtyFields2?.password ? (
                    <CheckIcon fontSize="small" color="primary"></CheckIcon>
                  ) : (
                    <CloseIcon fontSize="small" color="error"></CloseIcon>
                  )}
                </Grid>
                <Grid item container justifyContent="space-between">
                  <Typography
                    variant="subtitle2"
                    color={
                      !errors2?.password && !dirtyFields2?.password
                        ? "initial"
                        : !errors2?.password?.types.minLowercase &&
                          dirtyFields2?.password
                        ? "primary"
                        : "error"
                    }
                  >
                    Contain a lowercase letter
                  </Typography>
                  {!errors2?.password && !dirtyFields2?.password ? (
                    <CloseIcon fontSize="small" color="initial"></CloseIcon>
                  ) : !errors2?.password?.types.minLowercase &&
                    dirtyFields2?.password ? (
                    <CheckIcon fontSize="small" color="primary"></CheckIcon>
                  ) : (
                    <CloseIcon fontSize="small" color="error"></CloseIcon>
                  )}
                </Grid>
                <Grid item container justifyContent="space-between">
                  <Typography
                    variant="subtitle2"
                    color={
                      !errors2?.password && !dirtyFields2?.password
                        ? "initial"
                        : !errors2?.password?.types.minNumber &&
                          dirtyFields2?.password
                        ? "primary"
                        : "error"
                    }
                  >
                    Contain a number
                  </Typography>

                  {!errors2?.password && !dirtyFields2?.password ? (
                    <CloseIcon fontSize="small" color="initial"></CloseIcon>
                  ) : !errors2?.password?.types.minNumber &&
                    dirtyFields2?.password ? (
                    <CheckIcon fontSize="small" color="primary"></CheckIcon>
                  ) : (
                    <CloseIcon fontSize="small" color="error"></CloseIcon>
                  )}
                </Grid>
                <Grid item container justifyContent="space-between">
                  <Typography
                    variant="subtitle2"
                    color={
                      !errors2?.password && !dirtyFields2?.password
                        ? "initial"
                        : !errors2?.password?.types.minSymbol &&
                          dirtyFields2?.password
                        ? "primary"
                        : "error"
                    }
                  >
                    Contain a special character
                  </Typography>
                  {!errors2?.password && !dirtyFields2?.password ? (
                    <CloseIcon fontSize="small" color="initial"></CloseIcon>
                  ) : !errors2?.password?.types.minSymbol &&
                    dirtyFields2?.password ? (
                    <CheckIcon fontSize="small" color="primary"></CheckIcon>
                  ) : (
                    <CloseIcon fontSize="small" color="error"></CloseIcon>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="New Password"
                  id="password"
                  autoComplete="new-password"
                  {...register2("password")}
                  error={!!errors2?.password}
                  type={showPassword ? "text" : "password"} // <-- This is where the magic happens
                  InputProps={{
                    // <-- This is where the toggle button is added.
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  disabled={
                    errors2?.password || !dirtyFields2?.password ? true : false
                  }
                  name="confirmPassword"
                  label="Confirm New Password"
                  id="confirmPassword"
                  autoComplete="new-password"
                  {...register2("confirmPassword")}
                  error={!!errors2?.confirmPassword}
                  helperText={
                    errors2.confirmPassword
                      ? errors2.confirmPassword.message
                      : " "
                  }
                  type={showPasswordConfirm ? "text" : "password"} // <-- This is where the magic happens
                  InputProps={{
                    // <-- This is where the toggle button is added.
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPasswordConfirm}
                          onMouseDown={handleMouseDownPasswordConfirm}
                          edge="end"
                        >
                          {showPasswordConfirm ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  fullWidth
                  disabled={!isDirty2 || !isValid2}
                  variant="contained"
                  sx={{ mb: 2 }}
                >
                  Reset Password
                </Button>
              </Grid>
            </Grid>

            <Grid container justifyContent="flex-end">
              <Grid item></Grid>
            </Grid>
          </Box>
        )}
      </Box>
    </Container>
  );
}
