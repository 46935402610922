import React from "react";
import NavBar from "../NavBar";
import Itinerary from "./itinerary/Itinerary";
import "./TripMapper.css";
import { useState } from "react";
import SplitPane from "react-split-pane";

import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import ItineraryApiErrorAlert from "./itinerary/ItineraryApiErrorAlert";
import {
  getItineraryFromDb,
  getItineraryStatus,
  getItineraryRouteStatus,
} from "../redux/itinerary";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { Typography, Grid, Paper } from "@mui/material";
import throttle from "lodash.throttle";
import VectorMap from "./map/VectorMap";
import { useMap } from "react-map-gl";
import Box from "@mui/material/Box";
import { useWindowSize } from "@react-hook/window-size/throttled";
import { useTheme, useMediaQuery } from "@mui/material";
import AccountButton from "../auth/AccountButton";
import { useNavigate } from "react-router-dom";
import { createAlert } from "../redux/alert";

function TripMapper() {
  // default, min, and max split pane calculations
  //const { width } = useWindowDimensions();
  const [width, height] = useWindowSize();
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("md"));
  // load get and load itinerary
  const dispatch = useDispatch();
  const itineraryStatus = useSelector(getItineraryStatus);
  const routeStatus = useSelector(getItineraryRouteStatus);
  const [isInitiating, setIsInitiating] = useState(true);
  const { itineraryId } = useParams();

  useEffect(() => {
    resizeVectorMap();
  }, [width, height]);

  useEffect(() => {
    setIsInitiating(true);
    (async () => {
      try {
        await dispatch(getItineraryFromDb(itineraryId)).unwrap();

        setIsInitiating(false);
      } catch (e) {
        navigate("/itineraries", { replace: true });
        setIsInitiating(false);
        dispatch(
          createAlert({ message: "Unable to load itinerary", type: "error" })
        );
      }
    })();
  }, []);

  // refresh map if spit panes are changed
  const { vectorMap } = useMap();
  const resizeVectorMap = () => {
    if (!vectorMap) {
      return undefined;
    }
    vectorMap.resize();
  };

  if (width) {
    return (
      <>
        <Box height="100vh" display="flex" flexDirection="column">
          {isSmallDevice ? (
            <Box
              sx={{
                position: "fixed",
                zIndex: "3",
                ml: 2,
                mt: 1,

                display: isInitiating ? "none" : "",
              }}
            >
              <Paper
                elevation={1}
                sx={{ border: "none", borderRadius: "50px" }}
              >
                <AccountButton></AccountButton>
              </Paper>
            </Box>
          ) : (
            <Box>
              <NavBar></NavBar>
            </Box>
          )}

          {!isInitiating ? (
            width > height ? (
              <Box flex={2} style={{ height: "100%", position: "relative" }}>
                <SplitPane
                  split="vertical"
                  minSize={260}
                  maxSize={width - 380}
                  defaultSize={parseInt(width / 2, 10)}
                  onChange={throttle(resizeVectorMap, 30, {
                    leading: true,
                    trailing: true,
                  })}
                >
                  <div>
                    {routeStatus === "loading" ? (
                      <CircularProgress
                        size={40}
                        color="primary"
                        sx={{
                          position: "absolute",
                          bottom: 0,
                          right: 0,
                          zIndex: "2",
                          mb: 1,
                          mr: 1,
                        }}
                      ></CircularProgress>
                    ) : (
                      <></>
                    )}

                    <VectorMap />
                  </div>

                  <div>
                    <Itinerary />
                  </div>
                </SplitPane>
              </Box>
            ) : (
              <Box flex={2} style={{ height: "100%", position: "relative" }}>
                <SplitPane
                  split="horizontal"
                  minSize={200}
                  maxSize={height - 300}
                  defaultSize={parseInt(height / 2, 10)}
                  onChange={throttle(resizeVectorMap, 30, {
                    leading: true,
                    trailing: true,
                  })}
                >
                  <div>
                    {routeStatus === "loading" ? (
                      <CircularProgress
                        size={40}
                        color="primary"
                        sx={{
                          position: "absolute",
                          bottom: 0,
                          right: 0,
                          zIndex: "2",
                          mb: 1,
                          mr: 1,
                        }}
                      ></CircularProgress>
                    ) : (
                      <></>
                    )}

                    <VectorMap />
                  </div>

                  <div>
                    <Itinerary />
                  </div>
                </SplitPane>
              </Box>
            )
          ) : (
            <Grid
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing="15"
              container
              sx={{ height: "100%", position: "relative" }}
            >
              <Grid item>
                <CircularProgress size={60} />
              </Grid>
              <Grid item>
                <Typography variant="h5">Loading Itinerary</Typography>
              </Grid>
            </Grid>
          )}
        </Box>

        <ItineraryApiErrorAlert></ItineraryApiErrorAlert>
      </>
    );
  }
}

export default TripMapper;
