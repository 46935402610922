import React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { clearAlert, getAlert } from "./redux/alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


export default function AlertSnackbar() {

  const dispatch = useDispatch();

  const { alertSnackbarOpen, message, type } = useSelector(getAlert);

  function handleClose() {
    dispatch(clearAlert());
  }


  return (
    <Snackbar open={alertSnackbarOpen} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity={type}>
        {message}
      </Alert>
    </Snackbar>
  );
}
