import { createSlice } from "@reduxjs/toolkit";

export const alertSlice = createSlice({
  name: "alert",
  initialState: {
    alertSnackbarOpen: false,
    type: "", // "error","info","success","warning"
    message: "",
  },
  reducers: {
    createAlert: (state, action) => {
      state.alertSnackbarOpen = true;

      state.type = action.payload.type;
      if (!action.payload.message) {
        state.message = "";
      } else {
        state.message = action.payload.message;
      }
    },
    clearAlert: (state) => {
      state.alertSnackbarOpen = false;
    },
  },
});

export const { createAlert, clearAlert } = alertSlice.actions;

export const getAlert = (state) => state.alert;

export default alertSlice.reducer;
