import React from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { Box, Grid, IconButton, Skeleton } from "@mui/material";
import Typography from "@mui/material/Typography";

export default function ItemLoading({ item, index, dayIndex }) {
  return (
    <Box sx={{ p: "5px" }}>
      <Grid item container direction="row" justifyContent="space-between">
        <Grid item sx={{ alignSelf: "center" }}>
          <DragIndicatorIcon sx={{ fontSize: "1.5em" }} color="action" />
        </Grid>
        <Typography style={styles.address} align="center">
          <Skeleton width={200}></Skeleton>
        </Typography>
        <Grid item>
          <IconButton disabled={true} aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
}

const styles = {
  address: {
    fontWeight: 700,
  },
  chip: {
    fontWeight: 300,
  },
  note: { marginBottom: 30 },
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    backgroundColor: "#FFFFFF",
    border: "2px solid gray",
    borderRadius: "5px",
    boxShadow: 24,
  },
};
