import React from "react";
import styled from "styled-components";
import { CompactPicker } from "react-color";
import { Popover, ButtonBase } from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { updateDayColor } from "../../../redux/itinerary";
import { useEffect } from "react";

export default function EditDayColor({ dayColor, dayIndex }) {
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [color, setColor] = useState(dayColor);

  const [newColor, setNewColor] = useState(dayColor);

  useEffect(() => {
    setColor(dayColor);
  }, [dayColor]);

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    if (newColor !== dayColor) {
      dispatch(updateDayColor({ dayIndex, color: newColor }));
    }
  };

  const handleChange = (colorChange) => {
    setNewColor(colorChange.hex);
  };

  return (
    <>
      <ButtonBase
        sx={{
          "&:hover": {
            opacity: ".7",
          },
        }}
        onClick={handleClick}
      >
        <Swatch color={color}></Swatch>
      </ButtonBase>
      <Popover
        sx={{ ml: 1 }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
      >
        <PopoverArea>
          <CompactPicker
            color={newColor}
            onChange={handleChange}
          ></CompactPicker>
        </PopoverArea>
      </Popover>
    </>
  );
}

const Swatch = styled.div.attrs((props) => ({
  style: {
    backgroundColor: props.color,
  },
}))`
  height: 15px;
  width: 15px;
`;

const PopoverArea = styled.div`
  height: 91px;
  width: 245px;
`;
