export const getPrevGeo = (day, index, days) => {
  for (let i = day; i >= 0; i--) {
    const jValue = day === i ? index - 1 : days[i].items.length - 1;

    for (let j = jValue; j >= 0; j--) {
      //console.log("prev " + i + " " + j);
      if (days[i].items[j].includeInRouting) {
        return {
          lat: days[i].items[j].geo.lat,
          lon: days[i].items[j].geo.lon,
          itemId: days[i].items[j].itemId,
          day: i,
          index: j,
        };
      }
    }
  }
  return;
};

export const getNextGeo = (day, index, days) => {
  for (let i = day; i < days.length; i++) {
    const jValue = day === i ? index + 1 : 0;

    for (let j = jValue; j < days[i].items.length; j++) {
      //console.log("next " + i + " " + j);
      if (days[i].items[j].includeInRouting) {
        return {
          lat: days[i].items[j].geo.lat,
          lon: days[i].items[j].geo.lon,
          itemId: days[i].items[j].itemId,
          day: i,
          index: j,
        };
      }
    }
  }
  return;
};
