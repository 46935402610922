import React from "react";
import { Typography, Tooltip, IconButton, Menu, MenuItem } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import { useDispatch } from "react-redux";
import { updateRouteMode } from "../../../redux/itinerary";
import FlightIcon from "@mui/icons-material/Flight";
import DriveEtaIcon from "@mui/icons-material/DriveEta";

export default function RouteMenu({ dayIndex, index, item }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const dispatch = useDispatch();
  const handleClickOption = () => {
    dispatch(
      updateRouteMode({
        index,
        day: dayIndex,
        setIsFlight: !item.isFlight,
        itemId: item.itemId,
      })
    );
    handleClose();
  };
  return (
    <>
      <Tooltip title={`Route Options`} enterDelay={500} placement="right">
        <IconButton onClick={handleClick} size="small">
          <MoreHorizIcon></MoreHorizIcon>
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {item.isFlight ? (
          <MenuItem onClick={handleClickOption}>
            <Typography color="error">Change to Drive</Typography>
            <DriveEtaIcon
              color="error"
              fontSize="small"
              sx={{ ml: 1 }}
            ></DriveEtaIcon>
          </MenuItem>
        ) : (
          <MenuItem onClick={handleClickOption}>
            <Typography color="error">Change to Flight</Typography>
            <FlightIcon
              color="error"
              fontSize="small"
              sx={{ ml: 1 }}
            ></FlightIcon>
          </MenuItem>
        )}
      </Menu>
    </>
  );
}
