import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthContext";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import YupPassword from "yup-password";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import { CognitoUserAttribute } from "amazon-cognito-identity-js";
import { createAlert } from "../redux/alert";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { LoadingButton } from "@mui/lab";

YupPassword(Yup); // extend yup

export default function ChangeEmail({ getUserDetails, setExpanded }) {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { getSession, authenticate, logout, currentUser } = useAuth();

  const [loading, setLoading] = useState(false);

  const formValidationSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password length should be at least 8 characters"),
    newEmail: Yup.string()
      .required("An email address is required")
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Invalid email"
      ),
  });

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors, dirtyFields, isDirty, isValid },
  } = useForm({
    resolver: yupResolver(formValidationSchema, {
      validateAllFieldCriteria: true,
    }),
    defaultValues: {
      password: "",
      newEmail: "",
    },
    criteriaMode: "all",
    mode: "onChange",
  });

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const handleChangeEmail = (formData) => {
    setLoading(true);
    const password = formData.password;
    const newEmail = formData.newEmail;

    getSession().then(({ user }) => {
      authenticate(currentUser, password)
        .then(() => {
          const attributes = [
            new CognitoUserAttribute({ Name: "email", Value: newEmail }),
          ];

          user.updateAttributes(attributes, (e, results) => {
            if (e) {
              dispatch(createAlert({ message: e.message, type: "error" }));
            } else {
              setExpanded(false);
              reset();
              dispatch(
                createAlert({
                  message: `Confirmation code sent to ${newEmail}.`,
                  type: "warning",
                })
              );
              getUserDetails();
              setLoading(false);
            }
          });
        })
        .catch((e) => {
          // if user enters wrong password
          // error authenticating invalid username or password
          dispatch(
            createAlert({
              message: "Incorrect password, email not changed.",
              type: "error",
            })
          );
          reset();
          setLoading(false);
        });
    });
  };

  return (
    <Box component="form" onSubmit={handleSubmit(handleChangeEmail)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            name="password"
            label="Password"
            id="password"
            autoComplete="password"
            {...register("password")}
            error={!!errors?.password}
            helperText={errors.password ? errors.password.message : " "}
            type={showPassword ? "text" : "password"} // <-- This is where the magic happens
            InputProps={{
              // <-- This is where the toggle button is added.
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            id="newEmail"
            label="New Email Address"
            name="newEmail"
            autoComplete="new-email"
            {...register("newEmail")}
            error={!!errors?.newEmail}
            helperText={errors.newEmail ? errors.newEmail.message : " "}
          />
        </Grid>
      </Grid>
      <LoadingButton
        type="submit"
        fullWidth
        loading={loading}
        disabled={!isDirty || !isValid}
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        Change Email
      </LoadingButton>
      <Grid container justifyContent="flex-end">
        <Grid item></Grid>
      </Grid>
    </Box>
  );
}
