import React from "react";
import { Popup } from "react-map-gl";
import { Typography, Box, Card, CardMedia, CardContent } from "@mui/material";

import MyLocationIcon from "@mui/icons-material/MyLocation";
import { useDispatch, useSelector } from "react-redux";
import { getPopup, setPopup } from "../../redux/itinerary";
import PublicIcon from "@mui/icons-material/Public";
import ReactCountryFlag from "react-country-flag";
import "maplibre-gl/dist/maplibre-gl.css";
import "./VectorMap.css";

export default function CustomMarkerPopup() {
  const dispatch = useDispatch();
  const popupInfo = useSelector(getPopup);
  const handleSetPopupNull = () => {
    dispatch(setPopup({ item: null }));
  };

  return (
    <Popup
      anchor="bottom"
      longitude={Number(popupInfo.geo.lon)}
      latitude={Number(popupInfo.geo.lat)}
      onClose={() => handleSetPopupNull()}
      className={"popup-content"}
      closeButton={true}
    >
      <Card
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          p: 0,
        }}
      >
        {popupInfo.wiki && (
          <CardMedia
            component="img"
            height="110"
            image={popupInfo.wiki?.thumbnail.url}
            alt={popupInfo.wiki?.title}
          ></CardMedia>
        )}

        <CardContent sx={{ p: 1, height: "100%" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: "secondary",
              justifyContent: "center",
              alignItems: "start",
            }}
          >
            <Typography
              color="text.primary"
              variant="subtitle2"
              sx={{ textAlign: "center", mr: 1, mb: 0.5 }}
            >
              {popupInfo.geo.address_line1}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                alignItems: "center",
                mb: 0.5,
              }}
            >
              <PublicIcon
                fontSize="small"
                color="primary"
                sx={{
                  mr: 1,
                }}
              ></PublicIcon>
              <Typography color="text.primary" variant="caption" sx={{ mr: 1 }}>
                {popupInfo.geo.state && popupInfo.geo.state + ", "}
                {popupInfo.geo.country}
              </Typography>
              <ReactCountryFlag
                svg
                style={{
                  width: "1.5em",
                  height: "1.25em",
                }}
                countryCode={popupInfo.geo.country_code}
                aria-label={popupInfo.geo.country}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                alignItems: "center",
              }}
            >
              <MyLocationIcon
                fontSize="small"
                color="primary"
                sx={{
                  mr: 1,
                }}
              ></MyLocationIcon>
              <Typography color="text.primary" variant="caption">
                {popupInfo.geo.lat.toString().substring(0, 7)},{" "}
                {popupInfo.geo.lon.toString().substring(0, 7)}
              </Typography>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Popup>
  );
}
