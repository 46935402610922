import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  TextField,
  Box,
  Typography,
  Modal,
  Button,
  IconButton,
} from "@mui/material";
import * as Yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import { useAuth } from "./auth/AuthContext";
import { createAlert } from "./redux/alert";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export default function CreateItineraryModal({ getItineraries }) {
  const [open, setOpen] = useState(false);
  const handleOpenCreateItineraryModal = () => setOpen(true);
  const handleCloseAndGetItins = () => {
    getItineraries();
    reset();
    setOpen(false);
  };
  const handleClose = () => {
    reset();
    setOpen(false);
  };

  const { getToken } = useAuth();
  const dispatch = useDispatch();
  const [createLoading, setCreateLoading] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const navigate = useNavigate();

  const formValidationSchema = Yup.object().shape({
    title: Yup.string()
      .required("Title is required")
      .max(40, "Title can contain maximum of 40 Characters"),
    description: Yup.string().max(
      400,
      "Description can contain a maximum of 400 Characters"
    ),
  });

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors, dirtyFields, isDirty, isValid },
  } = useForm({
    resolver: yupResolver(formValidationSchema, {
      validateAllFieldCriteria: true,
    }),
    criteriaMode: "all",
    mode: "onChange",
  });

  const handleCreateItinerary = async (formData) => {
    setCreateLoading(true);
    try {
      // wait for the action to complete successfully
      const config = await getToken();

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_API}/itinerary/create`,
        {
          title: formData.title.trim(),
          description: formData.description.trim(),
        },
        config
      );
      // then
      handleCloseAndGetItins();
    } catch (e) {
      dispatch(
        createAlert({ message: "Unable to create itinerary", type: "error" })
      );
    }
    setCreateLoading(false);
  };

  const handleCreateAndNavigateToItinerary = async (formData) => {
    setOpenLoading(true);
    try {
      // wait for the action to complete successfully
      const config = await getToken();

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_API}/itinerary/create`,
        {
          title: formData.title,
          description: formData.description,
        },
        config
      );
      // then
      navigate(`../edit/${response.data.itineraryId}`);
    } catch (e) {
      dispatch(
        createAlert({ message: "Unable to create itinerary", type: "error" })
      );
    }
    setOpenLoading(false);
  };

  return (
    <>
      <Button
        variant="contained"
        fullWidth
        sx={{ mt: 2 }}
        onClick={handleOpenCreateItineraryModal}
      >
        Create New Itinerary
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Box style={styles.modalStyle}>
          <IconButton
            onClick={handleClose}
            sx={{ position: "fixed", right: 0, mr: 0.5, mt: 0.5 }}
          >
            <CloseIcon></CloseIcon>
          </IconButton>
          <Box
            sx={{
              my: 4,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant="h6">Create Itinerary Details</Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit(handleCreateItinerary)}
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="title"
                label="Title"
                name="title"
                autoFocus
                {...register("title")}
                error={!!errors?.title}
                helperText={errors.title ? errors.title.message : " "}
              />
              <TextField
                margin="normal"
                fullWidth
                id="description"
                label={"Description"}
                name="description"
                multiline
                minRows={3}
                maxRows={10}
                placeholder="Type your description here"
                {...register("description")}
                error={!!errors?.description}
                helperText={
                  errors.description ? errors.description.message : " "
                }
              />
              <LoadingButton
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 1, mb: 1 }}
                loading={createLoading}
                disabled={openLoading}
              >
                Create Itinerary
              </LoadingButton>
              <LoadingButton
                fullWidth
                variant="contained"
                sx={{ mt: 0, mb: 1 }}
                loading={openLoading}
                disabled={createLoading}
                onClick={handleSubmit(handleCreateAndNavigateToItinerary)}
              >
                Create and Open
              </LoadingButton>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

const styles = {
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    backgroundColor: "#FFFFFF",
    border: "2px solid gray",
    borderRadius: "5px",
    boxShadow: 24,
  },
};
